import * as _ from 'lodash';
import {
  FACTOR_SPECIALIZATION_ADAPTATION,
  FACTOR_SPECIALIZATION_EXPOSURE,
  FACTOR_SPECIALIZATION_HAZARD,
  FACTOR_SPECIALIZATION_IMPACT,
  FACTOR_SPECIALIZATION_RISK,
  FACTOR_SPECIALIZATION_VULNERABILITY,
 } from '../Constants';

//i.e., transform HumanCapitalAdaptation to Human Capital
export function enhanceFactorSubtype(factorType, subFactorType) {
  return _.startCase(_.replace(subFactorType, factorType, ''))
}


export function enhanceLinkType(linkType) {
  return _.kebabCase(linkType).replaceAll('-', ' ');
}

export function pluralize(factorType) {
  const plural = {
    [FACTOR_SPECIALIZATION_ADAPTATION]: 'Adaptation options',
    [FACTOR_SPECIALIZATION_EXPOSURE]: 'Exposures',
    [FACTOR_SPECIALIZATION_HAZARD]: 'Hazards',
    [FACTOR_SPECIALIZATION_IMPACT]: 'Impacts',
    [FACTOR_SPECIALIZATION_RISK]: 'Risks',
    [FACTOR_SPECIALIZATION_VULNERABILITY]: 'Vulnerabilities'
  };

  return plural[factorType];
}

export function colorize(factorType) {
  const plural = {
    [FACTOR_SPECIALIZATION_ADAPTATION]: '#A8ABAC',
    [FACTOR_SPECIALIZATION_EXPOSURE]: '#8DBF60',
    [FACTOR_SPECIALIZATION_HAZARD]: '#2A66FF',
    [FACTOR_SPECIALIZATION_IMPACT]: '#404649',
    [FACTOR_SPECIALIZATION_RISK]: '#2A66FF',
    [FACTOR_SPECIALIZATION_VULNERABILITY]: '#95B3FF'
  };

  return plural[factorType];
}

