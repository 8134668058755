import { Component } from "react";

class Usecases extends Component {

  render() {
    return <div className="wrapper-tools">
      <h3>Use cases</h3>
      <p>
        <strong>
          <a href="./pdf/CRISP_Flyer_ImpactStories.pdf"
            target="_blank">
              Applying the CRISP tool in Bolivia and Zambia: Identifying climate risks and adaptation options in agricultural and food system projects. Brief. International Center for Tropical Agriculture (CIAT)
            </a>          
        </strong>
        <br/>
        Heber, A., Lottje, C., Below, T., Voss, M., Rued, S., Saavedra, C., Bwalya, A., Chikomba, P. (2024)
      </p>  
    </div>
  }
}
export default Usecases;