import * as base64 from 'base-64';
import * as _ from 'lodash';
import { bindingsToJsMap } from "../sparql/ResultSet";
import { QueriesFetcher } from "../sparql/QueriesFetcher";
import { MULTIVALUED_PROPS, SANITIZE_PROPERTY } from '../Constants';

const SPARQL_ENDPOINT = process.env.REACT_APP_URL_SPARQL_ENDPOINT;

const sparqlQuery = async (query, log = {}) => {
  // Prepare the HTTP request via POST with URL-encoded parameters
  // https://www.w3.org/TR/sparql11-protocol/#query-via-post-urlencoded
  // The SPARQL protocol accepts also queries via GET or via POST directly
  const postQuery = async function(url = '', query, log = {}) {
      const response = await fetch(url, {
          method: 'POST',
          mode: 'cors',
          headers: { 
              // must set this content type when querying via POST
              'Content-Type': 'application/x-www-form-urlencoded',
              // get sure we get a JSON instead default XML
              'Accept': 'application/sparql-results+json',
              'X-Log': JSON.stringify(log)
          },
          body: 'query=' + query
      });

      // fetch won’t reject on HTTP error status even if the response
      // is an HTTP 404 or 500. Instead, it will resolve normally with
      // ok status set to false
      if(response.ok)
          return await response.json();
      else {
          // capture the error message
          const err = await response.json();            
          throw Error(
              (err.error + ': ' + err.message).replace(/(\r\n|\n|\r)/gm, "")
          );
      }
  };

  try {
    return bindingsToJsMap(
      await postQuery(
        SPARQL_ENDPOINT, 
        query,
        log
      )
    );
  } catch (error) {
    console.log(error);
  }
}

const doQuery = async(queryName, templateData = null) => {
  let results =  await sparqlQuery(
    await QueriesFetcher(queryName, templateData),
    {'query': queryName, ...(templateData ?? {})}
  );
 
  return _.chain(results)
    // nulls in properties are converted into [] or ''
    .map(
      row => _.mapValues(row, SANITIZE_PROPERTY)
    )
    // some properties are string containing multivalues (i.e., 'Micronesia; Melanesia; Southeast Asia; South Asia; East Asia' )
    // convert such string to array of values
    .forEach(
      row => {
        _.forEach(MULTIVALUED_PROPS, prop => {
          if(!_.isEmpty(row[prop])) 
            row[prop] = _.chain(row[prop]).split(';').map(_.trim).value()
        })
      }
    )
    .value();
}


export const getAllRegions = async() => {
  return await doQuery('all-regions');
}

export const getAllFarmingSystems = async() => {
  return await doQuery('all-farming-systems');
}

export const getAllCommodities = async() => {
  return await doQuery('all-commodities');
}

export const getAllImpactChains = async() => {
  return await doQuery('all-impact-chains');
}

export const getFactorTypes = async() => {
  return await doQuery('factor-types-subtypes');
}

export const getImpactChainsByContext = async(filters) => {
  /* group by category so we have object line like i.e.:
  {
    "commodities": [
      {"category": "commodities", "key": "Maize"},
      {"category": "commodities", "key": "Wheat"}
    ],
    "countries": [
      { "category": "countries", "key": "CHN"},
      {"category": "countries","key": "IRN"}
    ],
    "farmingSystem": [
      { "category": "farmingSystem", "key": "Highland Mixed (MENA)"}
    ]
  }*/  
  return await doQuery(
    'impact-chain-by-context', 
    _.groupBy(filters, 'category')
  );
}

export const getAllFactorsByICModel = async(ICModelName) => {
  return await doQuery(
    'all-factors-by-ICModel',
    ICModelName);
}

export const getAllRelatedFactorsByICModel = async(ICModelName) => {
  return await doQuery(
     'related-factors-to-factor-by-ICModel',
     ICModelName
  )
}

export const getImpactChainDescription = async(ICModelName) => {
  return await doQuery(
     'impact-chain-description',
     ICModelName
  )
}

export const getFarmingSystemProperties = async(FarmingSystemName) => {
  return await doQuery(
     'farming-system-properties',
     FarmingSystemName
  )
}

export const getFactorInContext = async(factorName, ICModelName) => {
  return await doQuery(
    'factor-in-context',
    {factorName, ICModelName}
  )
}

export const getFactorsInContext = async(ICModelName) => {
  return await doQuery(
    'factors-in-context',
    ICModelName
  )
}

export const getLink = async(ICModelName, fromFactorLabel,  toFactorLabel) => {
  return await doQuery(
    'link-by-factors',
    {ICModelName, fromFactorLabel,  toFactorLabel}
  )
}