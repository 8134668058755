
import { Component } from "react";
import { Button } from "react-bootstrap";

class Userguide extends Component {

  render() {    
    return <div className="wrapper-tools">
      <h3>User guide</h3>
      <p>
        This guide is addressed to users of the Climate Risk Planning and Management (CRISP) Tool, please download it here:
      </p>        
      <Button
      target="_blank"
        href="./pdf/CRISP_User_Guide.pdf"
        className="btn-view-impact-chains"
        variant="primary">
        Download User Guide
      </Button>
    </div>
  }
}
export default Userguide;