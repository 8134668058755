import { Component } from "react";
import { Alert } from "react-bootstrap";
import { VIEW_BACKGROUND, VIEW_CONTEXT_SELECTION, VIEW_USER_GUIDE } from "../../Constants";
import { Context } from "../../context/context";

class Home extends Component {
  
  static contextType = Context

  render() {
    return <div className="wrapper-home">
      <img src="./images/home/crisp-home.png" alt="CRISP"/>
      <p className="short">
        <span>CRISP</span> is an interactive tool that supports you to mainstream climate risk considerations into your project design and implementation. It specifically addresses project managers and practitioners in agriculture, rural development and food and nutrition security projects.
      </p>
      <p className="short">
        <span>CRISP</span> guides you through a process to <strong>understand</strong> climate related risks associated with specific agricultural systems, <strong>articulate</strong> science-based adaptation hypotheses, <strong>identify cascading impacts</strong> and review relevant <strong>adaptation options</strong>.
      </p>
      <h3>With CRISP you can</h3>
      <div className="tool-points">
        <div>
          {<svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">1</text>
          </svg>}
          Conduct a quick and simple climate risk screening and develop a climate-focused adaptation hypothesis to strengthen your project proposal. CRISP will highlight context-specific underlying drivers, vulnerability factors and potential cascading climate impacts (“Adaptation to what”).
        </div>
        <div>
          {<svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">2</text>
          </svg>}
          Identify or validate agricultural adaptation options that explicitly address agriculture system specific climate impacts. CRISP will provide you with adaptation options that help reduce specific vulnerabilities. (“Adaptation how”).
        </div>
        <div>
          {<svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">3</text>
        </svg>}
          Get insights to inform your project monitoring & evaluation plan. CRISP will identify adaptation options of interest and show which vulnerabilities and impacts are being addressed (“Adaptation tracking”).
        </div>
        <div>
          {<svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">4</text>
      </svg>}
          Get guidance on additional science-driven approaches and tools available to support climate actions through your project implementation. This website will provide you with references to relevant tools and guidelines that support you in adaptation implementation (“Adaptation implementation”).
        </div>
      </div>

      <h3>With CRISP you cannot</h3>
      <div className="tool-points">
        <div>
          {<svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">1</text>
          </svg>}
          Do a comprehensive, analytical and quantitative climate risk assessment (requires data and stakeholder involvement).
        </div>
        <div>
          {<svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">2</text>
          </svg>}
          Carry out the evaluation of specific mitigation/adaptation options.
        </div>
        <div>
          {<svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">3</text>
        </svg>}
        Explicitly consider trade-offs of adaptation options.
        </div>
      </div>

      <div className="home-alert">
        <Alert key='info' variant='info'>
          <Alert.Heading>How to start</Alert.Heading>
          Please go to <Alert.Link href="#" onClick={() => this.props.changeView(VIEW_BACKGROUND)}>The basics</Alert.Link> for a quick intro, <Alert.Link href="#" onClick={() => this.props.changeView(VIEW_USER_GUIDE)}>read the User Guide for further guidance</Alert.Link> and then get started by clicking on <Alert.Link href="#" onClick={() => this.props.changeView(VIEW_CONTEXT_SELECTION)}>Context Selection</Alert.Link>.
        </Alert>
      </div>      
      
      <h5>Partners</h5>
      <p className="short-footer">
        The <span>CRISP-Tool</span> was developed by the <a href="https://www.giz.de/en/html/index.html" target="_blank" rel="noreferrer">Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ)</a> on behalf of the <a href="https://www.bmz.de/en" target="_blank" rel="noreferrer">German Federal Ministry for Economic Cooperation and Development (BMZ)</a> in cooperation with the <a href="https://alliancebioversityciat.org/" target="_blank" rel="noreferrer">Alliance of Bioversity International and the International Centre for Tropical Agriculture (CIAT)</a>, <a href="https://www.eurac.edu/en" target="_blank" rel="noreferrer">Eurac Research</a> and the <a href="https://www.unibz.it/" target="_blank" rel="noreferrer">Free University of Bolzano/Bozen</a>.
      </p>
    </div>
  }  
}

export default Home;