import { Component, Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { VIEW_HOME, VIEW_OTHER_TOOLS, VIEW_RAW_DATA, VIEW_USER_GUIDE, VIEW_USE_CASES, VIEW_VIDEOS } from '../../Constants';
import { Modal } from 'react-bootstrap';

class HeaderBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModalAbout: false,
      showModalFAQ: false,
      showModalGlossary: false
    }
  }

  render() {
    let { showModalAbout, showModalFAQ, showModalGlossary } = this.state;

    const Mailto = ({ email = '', subject = '', body = '', children }) => {
      let params = subject || body ? '?' : '';
      if (subject) params += `subject=${encodeURIComponent(subject)}`;
      if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
      if (!email) email = process.env.REACT_APP_CONTACT_EMAIL;
    
      return <a href={`mailto:${email}${params}`}>{children}</a>;
    };

    return (
      <Fragment>
        <Modal
          size='xl'
          className='modal-about'
          show={showModalAbout}
          onHide={() => { this.setState({showModalAbout: false})}}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
            <h3>About</h3>
            <p>
              The CRISP tool supports Agri-food development projects to implement climate change
              adaptation. It allows them to conduct a rapid climate risk screening and develop climate-
              focused adaptation hypothesis to strengthen a project proposal. The tool thus also supports
              users to identify or validate adaptation options that explicitly address Agri-food system
              specific climate impacts and provides insights to inform a project’s monitoring and
              evaluation plan.
            </p>            
            <h3>The data</h3>
            <p>
              The CRISP tool provides information for climatic hazards, exposure, impacts, vulnerabilities, and adaptation options relevant for a specific farming system.
            </p>
            <p>
              A climate-risk oriented knowledge base was created by acquiring context-specific impact
              chain data on risk factors and their relationships from a comprehensive literature review.
              The literature included both peer-reviewed and grey literature synthesising the currently
              existing understanding of climate hazards and risk factors in the context of specific farming
              systems.
            </p>
            <p>
              More information on the creation of the dataset can be found in the report available
              through <a href="https://cgspace.cgiar.org/handle/10568/120424" rel="noreferrer" target="_blank">https://cgspace.cgiar.org/handle/10568/120424</a>
            </p>            
            <h3>Acknowledgements</h3>
            <p>
              The efforts of all the authors, contributors and institutions producing and making available
              the information used in this work are gratefully acknowledged. We also thank all of the
              testers who provided feedback on earlier versions of the tool to help us improve the final
              product. The CRISP tool has been produced by the Deutsche Gesellschaft für Internationale
              Zusammenarbeit (GIZ) on behalf of the German Federal Ministry for Economic Cooperation
              and Development (BMZ) through the Sector Project Rural Development, in cooperation with
              the Alliance of Bioversity International and the International Centre for Tropical Agriculture
              (CIAT), Eurac Research and the Free University of Bolzano.
            </p>
            <h3>Imprint</h3>
            <div className="imprint-logos">
              <a href='https://www.eurac.edu/en' target='_blank'>
                <img
                  src="./images/logos/eurac_logo_red_WEB_pos.png"
                  alt="Eurac Research"
                  style={{width: "17.5em", margin: "3em"}}
                />
              </a>
            </div>
            <p>
              Eurac Research is a private research centre, established in 1992 in Bolzano. Our research activity is rooted in local issues which are then developed on a global level, thanks to a multidisciplinary approach and an international vision. Studies focus on issues that affect people, their health and the environment around them, with the aim of improving life in the societies of the future. Often, in response to regional problems, the centre develops concrete solutions that can be applied in different contexts, exploring new avenues based on interdisciplinarity and scientific excellence. Today, Eurac Research has more than 650 collaborators from over 40 countries.
            </p>
            <p>
              <a href='https://www.eurac.edu/en' target='_blank'>
                https://www.eurac.edu/en
              </a>
            </p>
            <p>
              <strong>Eurac Research</strong><br/>
              Drususallee 1/Viale Druso 1<br/>
              39100 Bozen/Bolzano<br/>
              Phone: (+39) 0471055055
            </p>
            <p>
              <strong>Contact</strong>: Communication, T +39 0471 055 033, <a href='communication@eurac.edu' target='_blank'>communication@eurac.edu</a>
            </p>
            </div>

          </Modal.Body>
        </Modal>
        <Modal
          size='xl'
          className='modal-FAQ'
          show={showModalFAQ}
          onHide={() => { this.setState({showModalFAQ: false})}}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Frequently asked questions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Some of the most frequent questions include:
            </p>
            
            <p className='q'>1. Does CRISP also provide information about future climate risks?</p>
The CRISP knowledge base is the result of an extensive literature review on the topic of climate
risks for 22 farming systems. It contains information from a total of 960 publications whereby
the large majority is dated between 2014 and 2022. The risks identified represent currently
known climate risks and not a projection of future climate risks. However, some publications and
descriptions in the CRISP tool relate to projections of climatic hazards (e.g. sea level rise).
<p className='q'>2. The farming systems are rather geographically broad, and the local contexts require specific
information. How should I deal with that?</p>
The CRISP farming system-focused impact chains provide a first entry point to understanding the
complexity and cascading effects of all the potentially relevant climate risks in a given farming
system. The CRISP step-by-step process, however, has been designed so that the user has to
select/validate only the impact chain factors (e.g. climatic hazards and vulnerabilities) that are
relevant to that area. We strongly recommend doing this as a participatory process where you
engage with local communities, experts and partners, knowledgeable of these local conditions.
<p className='q'>3. How can I make selections if I am not familiar with my area of interest and relevant climate risk
aspects?</p>
If you are not familiar with the area for which you are developing a climate risk narrative, we
recommend talking to and involving local experts or someone who knows the area and is able to
make the necessary selections. The online based nature of the tool allows you to organise an
online session if in-person meetings are not possible.
<p className='q'>4. What if the farming system covering my area of interest is not listed in the Context Selection?</p>
Currently, CRISP covers 22 farming systems, so there may be cases in which you do not find the
relevant information for your context. However, there is potential to expand the knowledge base
to include further farming systems in future releases of the tool.
<p className='q'>5. What if the commodities I am interested in are not mentioned in the tool? How were the
commodities selected?</p>
The CRISP approach is not commodity-focused. The commodities listed in Table 1 shown on “The
Basics” page are examples of the commodities prevalent in a farming system based on the
research findings. The CRISP step-by-step validation approach explained above allows you to
narrow down and validate the relevant impact chain factors specific to the location of interest.
Although information on the respective commodities is available in the impact chains, they
should not form the entry point for the risk assessment.
<p className='q'>6. Why did the development team decide to use the Dixon, et al., 2001 farming system
classification rather than other existing ones?</p>
Dixon et al. 2001 (https://www.fao.org/3/Y1860E/y1860e00.htm) defines over 70 major farming
systems, focusing on the six main regions of the developing world. Biophysical factors define the
types of farming system that are possible, whilst socioeconomic factors determine the actual
farming system observable at a given time. For the purposes of the CRISP tool, the Dixon farming
systems are well suited because they are classified according to data on land area (percentage of

the region), agricultural population (percentage of the region) and prevalence of poverty. These
variables give a high-level indication of the exposure and vulnerability of the system to climate
change, the potential for agricultural development and the opportunity to alleviate poverty and
food insecurity. A total of 22 farming systems were selected. The selected systems are from the
five regions most often targeted by agricultural development projects and represent more than
50% of the agricultural populations in those regions.
<p className='q'>7. Does CRISP provide a prioritisation of climate impacts?</p>
The CRISP tool does not prioritise climate impacts or any of the other impact chain factors.
Factors can be prioritised by local communities, experts and stakeholders (in a workshop/virtual
CRISP session). Check the User guide/Step-by step section for further guidance.
<p className='q'>8. What should I do if the climatic hazard, impact or adaptation option I am interested in is not
included in the impact chains?</p>
Once you have generated and downloaded the CRISP report, you will be able to visualise a
summary table with the selected factors of the impact chain, associated with the farming system
of your interest.
If specific climate hazards, impacts or adaptation options relevant to your specific project
location (under this farming system) are not included, you will have to further add those factors
independently of the CRISP tool, and e.g., include them in your project climate risk narrative.
There is no way to add them in the online tool.
<p className='q'>9. Is it possible to add information to the CRISP knowledge base?</p>
This first release of the CRISP tool does not provide a data input function. You are welcome,
however, to share any relevant new information, backstopped with the source of evidence, with
the CRISP team (use {process.env.REACT_APP_CONTACT_EMAIL}). This additional local/expert knowledge will be considered
in the potential expansion of the CRISP knowledge base at a later stage.
<p className='q'>10. How frequently will the information be updated?</p>
This is the first release of the CRISP tool. The current CRISP project phase is focused on its
maintenance to enable its public use and on gathering feedback, use cases and lessons from
users. The extension of the knowledge base is not considered for this project phase, but it is a
possibility for future phases.
<p className='q'>11. How can I provide feedback to the CRISP team?</p>
If you have expert knowledge or any additional information relevant to specific Farming system Impact chains and backstopped with clear sources of evidence that would like to share with the CRISP team, do get in touch via email at: <Mailto subject="Getting in contact with CRISP" body="">{process.env.REACT_APP_CONTACT_EMAIL}</Mailto>
          </Modal.Body>        
        </Modal>
        <Modal
          size='xl'
          className='modal-FAQ'
          show={showModalGlossary}
          onHide={() => { this.setState({showModalGlossary: false})}}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Glossary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='q'>Adaptive capacity</p>
            The ability of systems, institutions, humans and other organisms to adjust to potential damage, to take advantage of opportunities or to respond to consequences (MA, 2005).
            
            <p className='q'>Agriculture/farming system</p>
            The CRISP tool uses the <a href='https://www.fao.org/family-farming/detail/en/c/273641/' target='_blank'>Dixon et al. 2001</a> (updated for Africa in 2019, (<a href='https://www.taylorfrancis.com/books/9781317332275' target='_blank'>Dixon et al., 2019</a>)) farming system classification to contextualise the climate risk impact chains to a manageable level of analysis. In this classification system, a farming system refers to a population of individual farm systems that have broadly similar resource bases, enterprise patterns, household livelihoods and constraints, and for which similar development strategies and interventions would be appropriate. Depending on the scale of the analysis, a farming system can encompass a few dozen or many millions of households’ (Dixon et al., 2001 - <a href='https://www.fao.org/3/Y1860E/y1860e00.htm' target='_blank'>https://www.fao.org/3/Y1860E/y1860e00.htm</a>).
            
            <p className='q'>Climate Change Adaptation (CCA)</p>
            In human systems, the process of adjustment to actual or expected climate and its effects, in order to moderate harm or exploit beneficial opportunities. In natural systems, the process of adjustment to actual climate and its effects; human intervention may facilitate adjustment to expected climate and its effects. See also CCA options, Adaptive capacity and Maladaptive actions (Maladaptation).

            <p className='q'>Climate extreme (extreme weather or climate event)</p>
            The occurrence of a value of a weather or climate variable above (or below) a threshold value near the upper (or lower) ends of the range of observed values of the variable. For simplicity, both extreme weather events and extreme climate events are referred to collectively as "climate extremes".

            <p className='q'>Climate information</p>
            Information about the past, current state, or future of the climate system that is relevant for mitigation, CCA and risk management. It may be tailored or ‘co-produced’ for specific contexts, taking into account users' needs and values.

            <p className='q'>(Climate) Risk Assessment</p>
            The qualitative and/or quantitative scientific estimation of risks.

            <p className='q'>Climate Risk Management (CRM)</p>
            Climate Risk Management includes all mechanisms and measures (such as plans, actions, strategies or policies) to reduce current and future climate risks. The management of current risk to climate extremes is typically covered by the existing Disaster Risk Reduction (DRR) mechanism. Climate Change Adaptation (CCA) involves the process of adapting current CRM practices to the actual or anticipated impacts of climate change in order to limit damage or take advantage of positive opportunities. This includes adapting to the increasing intensity and frequency of climate extremes, as well as slow-onset processes (such as sea-level rise) and emerging climate risks. Today, CCA and DRR are seen as integral constituent parts of successful CRM.

            <p className='q'>Compound risks </p>
            They arise from the interaction of hazards, which may be characterised by single extreme events or multiple coincident or sequential events that interact with exposed systems or sectors.

            <p className='q'>Coping capacity</p>
            The ability of people, institutions, organisations and systems, using available skills, values, beliefs, resources and opportunities, to address, manage and overcome adverse conditions in the short to medium term (IPCC, 2012; UNISDR, 2009).The ability of people, institutions, organisations and systems, using available skills, values, beliefs, resources and opportunities, to address, manage and overcome adverse conditions in the short to medium term (IPCC, 2012; UNISDR, 2009).

            <p className='q'>Exposure</p>
            The presence of people; livelihoods; species or ecosystems; environmental functions, services, and resources; infrastructure; or economic, social, or cultural assets in places and settings that could be adversely affected. 

            <p className='q'>Hazard</p>
            Hazard The potential occurrence of a natural or human-induced physical event or trend that may cause loss of life, injury, or other health impacts, as well as damage and loss to property, infrastructure, livelihoods, service provision, ecosystems and environmental resources.

            <p className='q'>Impact chains</p>
            They permit the structuring of cause - effect relationships between drivers and/or inhibitors affecting a system. Impact chains allow for a visualisation of interrelations and feedbacks, help to identify the key impacts, on which level they occur and allow visualising which climatic hazards may lead to them. They further help to clarify and/or validate the objectives and the scope of the CRA and are a useful tool to involve stakeholders (adapted from (Fritzsche et al., 2015).

            <p className='q'>Impacts</p>
            Impacts generally refer to effects on lives, livelihoods, health and wellbeing, ecosystems and species, economic, social and cultural assets, services (including ecosystem services), and infrastructure. Impacts may be referred to as consequences or outcomes and can be adverse or beneficial. 

            <p className='q'>National Adaptation Plan (NAP)</p>
            'National adaptation plans (NAPs) are means of identifying medium- and long-term CCA needs, developing and implementing strategies and programmes to address those needs. It is a continuous, progressive and iterative process to formulate and implement NAPs which follows a country-driven, gender-sensitive, participatory and fully transparent approach' (UNFCC, 2021).

            <p className='q'>Risk</p>
            The potential for adverse consequences for human or ecological systems, recognising the diversity of values and objectives associated with such systems. In the context of climate change, risks can arise from potential impacts of climate change as well as human responses to climate change. Relevant adverse consequences include those on lives, livelihoods, health and wellbeing, economic, social and cultural assets and investments, infrastructure, services (including ecosystem services), ecosystems and species. <br/>
            In the context of climate change impacts, risks result from dynamic interactions between climate-related hazards with the exposure and vulnerability of the affected human or ecological system to the hazards. Hazards, exposure and vulnerability may each be subject to uncertainty in terms of magnitude and likelihood of occurrence, and each may change over time and space due to socio-economic changes and human decision-making.


            <p className='q'>Risk assessment</p>
            The qualitative and/or quantitative scientific estimation of risks. See also Risk management and Risk perception.

            <p className='q'>Risk management</p>
            Plans, actions, strategies or policies to reduce the likelihood and/or magnitude of adverse potential consequences, based on assessed or perceived risks.

            <p className='q'>Sensitivity</p>
            The degree to which a system or species is affected, either adversely or beneficially, by climate variability or change. The effect may be direct (e.g. a change in crop yield in response to a change in the mean, range, or variability of temperature) or indirect (e.g. damages caused by an increase in the frequency of coastal flooding due to sea level rise).

            <p className='q'>Vulnerability</p>
            The propensity or predisposition to be adversely affected. Vulnerability encompasses a variety of concepts and elements, including sensitivity or susceptibility to harm and lack of capacity to cope and adapt. See also Exposure, Hazard and Risk.                 
          </Modal.Body>        
        </Modal>
      <Navbar bg="primary" variant="dark" className='crisp-navbar' sticky='top'>
        <Container fluid>
          <Navbar.Brand>
            <img
              style={{cursor: 'pointer'}}
              onClick={() => this.props.changeView(VIEW_HOME)}       
              src="./images/logo-white.png"
              className="d-inline-block align-top"
              alt="CRISP"
            />
            <span style={{cursor: 'pointer'}}
              onClick={() => this.props.changeView(VIEW_HOME)}>
              CRISP
            </span>
            <span className='crisp'>Climate Risk Planning & Managing Tool for Development Programmes in Agri-food Systems</span>

          </Navbar.Brand>
          <Nav className='crisp-navbar-options'>
            <NavDropdown title="Help" id="navbarScrollingDropdown2" style={{paddingTop:'7px'}}>
                <NavDropdown.Item href="#glossary" onClick={() => this.setState({showModalGlossary: true})}>
                  Glossary
                </NavDropdown.Item>
                <NavDropdown.Item href="#faq" onClick={() => this.setState({showModalFAQ: true})}>
                  FAQ
                </NavDropdown.Item>
                <NavDropdown.Item href="#faq">
                  <Mailto subject="Getting in contact with CRISP" body="">
                    Contact us
                  </Mailto>
                </NavDropdown.Item>
              </NavDropdown>

            <NavDropdown title="Resources" id="navbarScrollingDropdown3" style={{paddingTop:'7px'}}>
              <NavDropdown.Item
                onClick={() => this.props.changeView(VIEW_USER_GUIDE)}>
                User guide
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => this.props.changeView(VIEW_USE_CASES)}>
                Use cases
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => this.props.changeView(VIEW_VIDEOS)}>
                Tutorial videos
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => this.props.changeView(VIEW_OTHER_TOOLS)}>
                Other tools
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => this.props.changeView(VIEW_RAW_DATA)}>
                CRISP data
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link href="#about" style={{paddingTop:'15px'}} onClick={() => this.setState({showModalAbout: true})}>About</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      </Fragment>
    );
  }  
}

export default HeaderBar;