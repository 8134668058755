import React, { Component, Fragment } from 'react';
import * as _ from 'lodash';
import { Provider } from './context/provider';
import ImpactChainFacetBrowser from './components/FacetBrowser/ImpactChainFacetBrowser';
import HeaderBar from './components/HeaderBar/HeaderBar';
import HeaderBarMobile from './components/HeaderBar/HeaderBarMobile';
import SubHeaderBar from './components/SubHeaderBar/SubHeaderBar';
import ICNetwork from './components/ICNetwork/ICNetwork';
import ICTable from './components/ICTable/ICTable';
//import { Parser as SparqlParser, Generator as SparqlGenerator } from 'sparqljs';
//import { getAllImpactChains, getImpactChainByContext } from './data/DataFetcher';
import { Context } from './context/context';
import { Button, Badge, ListGroup, Modal, Spinner, Toast, OverlayTrigger, Popover, Navbar, Container, Alert } from 'react-bootstrap';
import {
  MODAL_MESSAGE_IMPACT_CHAIN_SELECT, 
  VIEW_CONTEXT_SELECTION, 
  VIEW_NETWORK, 
  VIEW_HOME, 
  FACTOR_ICONS, 
  FACTOR_ICON_SIZE, 
  FACTOR_SPECIALIZATION_HAZARD, 
  FACTOR_NAME_SPLIT_STRING,
  VIEW_BACKGROUND,
  FACTOR_SPECIALIZATION_IMPACT,
  FACTOR_SPECIALIZATION_EXPOSURE,
  FACTOR_SPECIALIZATION_VULNERABILITY,
  FACTOR_SPECIALIZATION_ADAPTATION,
  FACTOR_SPECIALIZATION_RISK,
  VIEW_TABLE,
  VIEW_OTHER_TOOLS,
  VIEW_USER_GUIDE,
  VIEW_VIDEOS,
  VIEW_USE_CASES,
  VIEW_RAW_DATA
} from './Constants';
import Home from './components/Home/Home';
import { isMobile } from 'react-device-detect';
import Background from './components/Background/Background';
import Tools from './components/Tools/Tools';
import { InfoCircleFill } from 'react-bootstrap-icons';
import Userguide from './components/Userguide/Userguide';
import Videos from './components/Videos/Videos';
import Usecases from './components/Usecases/Usecases';
import Rawdata from './components/Rawdata/Rawdata';


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal:false,
      // default view once we have data
      view:VIEW_CONTEXT_SELECTION,
      // factor types selected by default in the IC selection
      defaultFactorTypes: [
        FACTOR_SPECIALIZATION_HAZARD,        
        /*FACTOR_SPECIALIZATION_IMPACT,
        FACTOR_SPECIALIZATION_EXPOSURE,
        FACTOR_SPECIALIZATION_VULNERABILITY,
        FACTOR_SPECIALIZATION_ADAPTATION, 
        FACTOR_SPECIALIZATION_RISK*/
      ],
      // selected Impact chain name
      //ICModelName: undefined
      showNetworkHelp:false
    };
  }



  async componentDidMount() {    
    
    /*
    // Parse a SPARQL query to a JSON object
    var parser = new SparqlParser({ skipValidation: true });
    var parsedQuery = parser.parse(
    `PREFIX rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#>
    PREFIX rdfs: <http://www.w3.org/2000/01/rdf-schema#>
    PREFIX dcterms: <http://purl.org/dc/terms/>
    PREFIX crisp: <http://www.crisp.org/crisp#>
    SELECT 
    #distinct ?ICModel ?icmodelName ?factor 
    distinct ?commodityTitle ?icmodel
    WHERE {
      ?uri a crisp:CommodityInContext .
      ?uri crisp:commodity/dcterms:title ?commodityTitle .
        ?uri crisp:in/dcterms:title ?icmodel .
        FILTER (LCASE(STR(?commodityTitle)) = LCASE('Lentils') || LCASE(STR(?commodityTitle)) = LCASE('Sheep')) . 
    }`
      );

    console.log('parsedQuery', parsedQuery);

    // Regenerate a SPARQL query from a JSON object
    var generator = new SparqlGenerator({});
    var generatedQuery = generator.stringify(parsedQuery);
    console.log(generatedQuery);*/
    
    //console.table(await getAllImpactChains());
  }
  

  hideModal(context) {
    context.resetFactorInContext();
  }



  changeView = (view) => {    
    // avoid race condition:
    // while doing the report,network is forced to be displayed (so we get the svg)
    // once report is done, we force to hide it again, unless we are already in network view
    // if we are, the flag showICNetwork remains to true, hence network is shown when we jump to other views

    // so make sure flag is set to false as soon as we change to other view different from network view
    this.setState({
      view,
      showICNetwork: false
    });
  }



  changeDefaultFactors = (factorType) => {
    let defaultFactorTypes = _.cloneDeep(this.state.defaultFactorTypes);
    let index = defaultFactorTypes.findIndex(el => _.isEqual(el, factorType));
   
    if(index !== -1)
      defaultFactorTypes.splice(index, 1);    
    else
      defaultFactorTypes = [...defaultFactorTypes, factorType];

    this.setState({defaultFactorTypes});
  }

  selectAllFactors = () => {
    this.setState({
      defaultFactorTypes: [
        FACTOR_SPECIALIZATION_HAZARD,        
        FACTOR_SPECIALIZATION_IMPACT,
        FACTOR_SPECIALIZATION_EXPOSURE,
        FACTOR_SPECIALIZATION_VULNERABILITY,
        FACTOR_SPECIALIZATION_ADAPTATION, 
        FACTOR_SPECIALIZATION_RISK
      ]
    })
  }


  resetICModelName = () => this.setState({ICModelName:undefined});


  render() {
    let { defaultFactorTypes } = this.state;

    return (
      <div className="App">
        {
          
          isMobile && <Fragment>
            <HeaderBarMobile/>
            <br/>
            <Toast>
              <Toast.Header closeButton={false}>
                <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                <strong className="me-auto">CRISP</strong>
              </Toast.Header>
              <Toast.Body>This application is not available on a mobile device, please visit the website on a desktop device.</Toast.Body>
            </Toast>
          </Fragment>          
        }
        {
          !isMobile && <Provider>
            <Context.Consumer>
              {
                context => <Fragment>
                  <HeaderBar changeView={context.setView}/>
                  <SubHeaderBar view={context.view} changeView={context.setView}/>                
                  {
                    ![
                      VIEW_CONTEXT_SELECTION, 
                      VIEW_HOME, 
                      VIEW_BACKGROUND,
                      VIEW_TABLE,
                      VIEW_OTHER_TOOLS,
                      VIEW_USER_GUIDE,
                      VIEW_VIDEOS,
                      VIEW_USE_CASES,
                      VIEW_RAW_DATA
                    ].includes(context.view) && <div className='ic-model-name'>
                            <strong className="me-auto">{context.ICModelName}</strong>
                            <small>
                              <input
                                type="checkbox"
                                checked={context.onlySelectedFactorsVisible}
                                onChange={
                                  () => context.showOnlySelectedFactors()
                                }
                              />
                              <span>Show only selected factors and its connections</span>
                            </small>
                            <small>
                              <input
                                type="checkbox"
                                checked={context.clusteredNetworkLayout}
                                onChange={
                                  () => context.setClusteredNetworkLayout()
                                }
                              />
                              <span>Show factors grouped by type</span>
                            </small>
                            <div>
                            <OverlayTrigger
                              trigger="click"
                              key={"left"}
                              placement={"left"}
                              overlay={
                                <Popover id={`popover-positioned`}>
                                  <Popover.Body>                                    
                                      <p>
                                        <strong>If you landed here with the climate hazards visible,</strong>
                                        <ul>
                                          <li>
                                            Explore those first by hovering over the individual icons to read a short description on the right hand side of your screen. Select those climatic <u>Hazards</u> that are relevant for your area under review by clicking on the icon once.
                                          </li>
                                          <li>
                                          As a next step add <u>Impacts</u> to your impact chain by clicking on "Impact" in the legend in the right-hand pane.
                                          </li>
                                          <li>
                                          Now explore the impacts, read the short descriptions and select the relevant ones.
                                        Continue building your impact chain by adding, exploring and selecting first <u>Vulnerabilities</u> and last <u>Adaptation</u> options.
                                          </li>
                                          <li>
                                          As a last step activate <u>Exposure</u> and <u>Risk</u>.
                                          </li>
                                        </ul>
                                      </p>
                                      <p>
                                        <strong>If you landed here with the Adaptation visible,</strong>
                                        <ul>
                                          <li>
                                            Explore those first by hovering over the individual icons to read a short description on the right hand side of your screen.
                                          </li>
                                          <li>
                                            Select those <u>Adaptation</u> options that are relevant for your project and area under review by clicking on the icon once.
                                          </li>
                                          <li>
                                            As a next step add the <u>Vulnerabilities</u> to your impact chain by clicking on "Vulnerability" in the legend in the right-hand pane. Explore the vulnerabilities by reading the short descriptions. Select the relevant ones.
                                            </li>
                                          <li>
                                            Continue building your impact chain " backwards" by adding, exploring and selecting <u>Impacts</u> and then climatic <u>Hazards</u>.
                                          </li>
                                          <li>
                                            As a last step activate <u>Exposure</u> and <u>Risk</u>.
                                          </li>
                                        </ul>                                        
                                      </p>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <InfoCircleFill size={24} color='rebeccapurple'/> 
                            </OverlayTrigger>
                            </div>
                      </div>
                  }                                
                  
                  { /* modal impact chain and factor types selector */}
                  <Modal
                    className='modal-impact-chain-selector'
                    centered
                    size='lg'
                    show={context.modalMessage === MODAL_MESSAGE_IMPACT_CHAIN_SELECT} 
                    backdrop="static"
                    keyboard={false}>
                      <Modal.Body>                            
                        <div>
                            <div>
                              <h5>&#8594; Selection of factors:</h5>
                              <p>
                                Define the entry point of your Climate risk assessment:
                                <a href="pdf/CRISP_User_Guide.pdf#page=14" target="_blank">
                                  <span className="userguide-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                      <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                                    </svg>
                                  </span>
                                </a>
                              </p>
                              <p className='instruction'>
                              If you want to answer the question <span>“Which are the main climate-related hazards, impacts, vulnerabilities and potential adaptation options relevant to the farming system in which my agricultural development project will be implemented?"</span>, <strong>select Hazard from the list below</strong>.
                              </p>
                              <p className='instruction'>If you want to answer the questions: <span>"Are the adaptation options promoted by my project responding to all the main climatic hazards and impacts affecting the specific farming system where they are implemented? Are there additional suitable adaptation options my project is not yet implementing and that would be worth considering?"</span>, <strong>select Adaptation from the list below.</strong></p>
                              <ListGroup variant="flush">
                                {
                                  _.keys(FACTOR_ICONS).map((factorType, i) => <ListGroup.Item key={i}
                                      action
                                      onClick={() => this.changeDefaultFactors(factorType)}
                                    >
                                    <div className='factor-initial-selector-item'>
                                      <input type="checkbox" readOnly checked={defaultFactorTypes.includes(factorType)}/>
                                      <svg width={FACTOR_ICON_SIZE} height={FACTOR_ICON_SIZE}>
                                          <image xlinkHref={FACTOR_ICONS[factorType]}
                                            x={0}
                                            y={0}
                                            width={FACTOR_ICON_SIZE}          
                                            height={FACTOR_ICON_SIZE}/>
                                        </svg>
                                        <span>{factorType}</span>
                                    </div>                              
                                    </ListGroup.Item>)
                                }
                              </ListGroup>
                              <p>
                                It is recommended to start with one risk component, e.g. hazard.                                                                                              
                                <br/>
                                <Button
                                  onClick={this.selectAllFactors}
                                  variant="outline-primary" 
                                  size="sm">
                                  Select all
                                </Button>
                              </p>                              
                            </div>
                            {/* 
                            <div className="right">
                              <h5>&#8594; Selection of the Impact Chain:</h5>
                              <p>{context.modalMessage}</p>
                              <ListGroup variant="flush">
                                {context.ICModelNames.map((ICModelName, i) => <ListGroup.Item
                                  key={i}
                                  action
                                  onClick={() => this.setState({ICModelName}) }
                                  >
                                    <div className='ic-initial-selector-item'>
                                      <input type="radio" readOnly checked={ICModelName.ICModelName === this.state.ICModelName?.ICModelName}/>
                                      {i+1}. {ICModelName.ICModelName}
                                    </div>                                
                                </ListGroup.Item>)}                                                
                              </ListGroup>
                            </div>
                            */}
                        </div>    
                      </Modal.Body>                    
                      <Modal.Footer>
                        <Alert key='info' variant='info'>
                          Before moving into the visualization of the impact chain, feel free to read the <a href="pdf/CRISP_User_Guide.pdf#page=14" target="_blank">User Guide</a> for further guidance.
                        </Alert>
                        <Button variant="secondary" onClick={() => context.setModalWindowMessage()}>
                          Back
                        </Button>
                        <Button
                          onClick={() => context.fetchFactorFromImpactChain(context.ICModelName, defaultFactorTypes)}
                          variant={"success"}
                          className="btn-view-impact-chains">
                            Explore Impact Chain
                        </Button>
                    </Modal.Footer>
                  </Modal>

                  { /* modal generic */}
                  <Modal
                    centered
                    size="sm"
                    show={!_.isEmpty(context.modalMessage) && context.modalMessage !== MODAL_MESSAGE_IMPACT_CHAIN_SELECT} 
                    backdrop="static"
                    keyboard={false}>
                      <Modal.Body>
                        <div style={{textAlign:'center'}}>
                          <Spinner animation="border"/>
                          <div>{context.modalMessage}</div>
                        </div>
                      </Modal.Body>
                  </Modal>
                  
                  { /* modal for selected factor */}
                  <Modal
                    show={context.factorInContext} 
                    onHide={ () => this.hideModal(context) }
                    >
                    <Modal.Header closeButton>
                      <Modal.Title>{context.factorInContext?.factorName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        {context.factorInContext?.contextualDescription}
                      </p>
                      <ul>
                        {
                          context.factorInContext?.resources && 
                          context.factorInContext?.resources
                            .split(';')
                            .map(resource => {
                              let citationUrl = resource.split(FACTOR_NAME_SPLIT_STRING);
                              return _.isEmpty(citationUrl[1]) ?
                                <li> {citationUrl[0]} </li>
                                :
                                <li><a href={citationUrl[1]} rel="noreferrer" target="_blank">{citationUrl[0]}</a></li>;
                            })                            
                        }
                      </ul>
                      {
                        context.factorInContext?.tags && context.factorInContext?.tags.split(';').map(tag => <Badge bg='secondary'>{tag}</Badge>)
                      }
                      </Modal.Body>                  
                  </Modal>
                  <div style={{zIndex: 10}}>
                  { 
                    context.view === VIEW_HOME ?
                      <Home changeView={context.setView}/> :
                    context.view === VIEW_BACKGROUND ?
                      <Background changeView={context.setView}/> :
                    context.view === VIEW_OTHER_TOOLS ?
                      <Tools changeView={context.setView}/> :
                      context.view === VIEW_USER_GUIDE ?
                      <Userguide changeView={context.setView}/> :
                    context.view === VIEW_USE_CASES ?
                      <Usecases changeView={context.setView}/> :
                    context.view === VIEW_VIDEOS ?
                      <Videos changeView={context.setView}/> :
                    context.view === VIEW_RAW_DATA ?
                      <Rawdata changeView={context.setView}/> :
                    context.view === VIEW_CONTEXT_SELECTION ? 
                      <ImpactChainFacetBrowser changeView={context.setView} resetICModelName = {this.resetICModelName}/> : 
                    context.view === VIEW_TABLE ?                      
                      <ICTable/> : <div/>
                  }
                  </div>                  
                  <div style={{zIndex: 3, display:context.view === VIEW_NETWORK || context.showICNetwork? 'block':'none'}}>
                    { !_.isEmpty(context.factorsInContext) && <ICNetwork
                        rejectedFactorTypes={context.rejectedFactorTypes}
                        rejectedFactorSubTypes={context.rejectedFactorSubTypes}
                        selectedFactors={context.selectedFactors}
                        onlySelectedFactorsVisible={context.onlySelectedFactorsVisible}
                        clusteredNetworkLayout={context.clusteredNetworkLayout}
                      />
                    }
                  </div>
                  <footer className='footer'>
                    <div className="container d-flex justify-content-center">
                        <a href='https://www.bmz.de/en' target='_blank'>
                          <img
                            src="./images/logos/BMZ_ZE_IAd_E-01.jpg"
                            alt="Federal Ministry for Economic Cooperation and Development"                        
                          />
                        </a>
                        <a href='https://alliancebioversityciat.org/' target='_blank'>
                          <img
                            src="./images/logos/Alliance Logo Refresh-EN color.jpg"
                            alt="Alliance Bioversity & CIAT"
                          />
                        </a>     
                        <a href='https://www.eurac.edu/en' target='_blank'>
                          <img
                            src="./images/logos/eurac-research.jpg"
                            alt="Eurac Research"
                          />
                        </a>                        
                        <a href='https://www.giz.de/en/html/index.html' target='_blank'>
                          <img
                            src="./images/logos/gizlogo-unternehmen-de-rgb-72.jpg"
                            alt="GIZ"
                          />
                        </a>                                                                        
                    </div>
                  </footer>              
                </Fragment>
              }
            </Context.Consumer>          
          </Provider>
        }        
      </div>
    );
  }  
}

export default App;