export const toolsData = [
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Agriculture",
    "Theme": "Adaptation",
    "Type of resource": "Tool",
    "Resource Name": "Agriculture Adaptation Atlas",
    "Institution (lead)": "CGIAR",
    "Description": "The Agriculture Adaptation Atlas provides the user with information on climate risk levels (outputs are available as maps, charts and tables) for three time periods (baseline, 2030 and 2050) and associated solutions. The tool gives summary statistics for different spatial units (smallest is country level). In addition, geospatial data can be downloaded for hazards, exposure, adaptive capacity and solutions (i.e. adaptation options).",
    "web page": "https://adaptationatlas.cgiar.org/",
    "Area covered": "Global",
    "Target user": "Investor; Researcher; Project planner; Policy maker"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Climate Data; Historic and future climate impacts",
    "Type of resource": "Data",
    "Resource Name": "Climate Change Knowledge Portal (CCKP)",
    "Institution (lead)": "World Bank Group",
    "Description": "The Climate Change Knowledge Portal (CCKP) is the hub for climate-related information, data, and tools for the World Bank Group. It provides global data on historic and future climate impacts and vulnerabilities as well as disaster risks, and socio-economic factors.  The data can be explored via national, sub-national and watershed views.\nThe data is also synthesised in easily accessible Climate Risk Country Profiles which describe specific climate change impacts on key sectors. CCKP’s Climate Risk Country Profiles present a high-level assessment of physical climate risks for a country, providing insight for decision-makers into the potential for increasing, expanding, and emerging risks across space and time, and for different climate futures.",
    "web page": "https://climateknowledgeportal.worldbank.org/",
    "Area covered": "Global",
    "Target user": "Development practitioner; Policy maker"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Climate Data",
    "Type of resource": "Data",
    "Resource Name": "Copernicus Climate Data Store",
    "Institution (lead)": "European Commission",
    "Description": "The Copernicus Climate Data Store provides global climate reanalysis data (1950 to present) which is hourly and monthly averaged, seasonal forecasts, climate projections, and observational data, amongst others. Datasets can be searched temporally (future, past or present) and by sector.",
    "web page": "https://cds.climate.copernicus.eu/cdsapp#!/home",
    "Area covered": "Global",
    "Target user": "Development practitioner; Project planner; Researcher; Policy maker"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Climate Data",
    "Type of resource": "Data",
    "Resource Name": "NOAA Climate Data Online",
    "Institution (lead)": "National Oceanic and Atmospheric Administration",
    "Description": "NOAA Climate Data Online provides free access to global historic weather and climate data in addition to station history information.",
    "web page": "https://www.ncdc.noaa.gov/cdo-web/datasets",
    "Area covered": "Global",
    "Target user": "Policy maker;  Development practitioner; Researcher; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Climate Data",
    "Type of resource": "Data",
    "Resource Name": "IRI/Columbia Climate Data Library",
    "Institution (lead)": "International Research Institute for Climate and Society",
    "Description": "The IRI/Columbia Climate Data Library is an online data repository and analysis tool that allows users to view, analyse and download climate-related data. Present climate conditions can be monitored and analysed using Maproom. Data can be presented in visually interesting formats such as animation.",
    "web page": "https://iri.columbia.edu/resources/data-library/",
    "Area covered": "Global",
    "Target user": "Policy maker;  Development practitioner; Researcher; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Climate Data",
    "Type of resource": "Data",
    "Resource Name": "IPCC WGI Interactive Atlas",
    "Institution (lead)": "Intergovernmental Panel of Climate Change",
    "Description": "The IPCC WGI Interactive Atlas enables temporal and spatial analyses of trends and changes in key atmospheric and oceanic variables, extreme indices and climatic impact-drivers, as obtained from several global and regional observation and model-simulated datasets used in the IPCC WGI report. Two interfaces are available: a simple interface for the general public, media, teaching and decision-makers and an advanced interface for researchers and practitioners.",
    "web page": "IPCC WGI Interactive Atlas",
    "Area covered": "Global",
    "Target user": "Media;  Decision-maker; Researcher; Development practitioner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Livestock; Crops",
    "Theme": "Farming Systems",
    "Type of resource": "Guide",
    "Resource Name": "Farming systems and poverty",
    "Institution (lead)": "World Bank Group",
    "Description": "This resource explains the farming system concept and describes 25 farming systems that have the greatest potential for poverty and hunger reduction and economic growth in the next few decades. The potential of the farming systems are considered according to five household strategies for escaping poverty and hunger, as follows: (a) intensification of production; (b) diversification of agricultural activities; (c) increased farm size; (d) expansion in off-farm income; and, (e) complete exit from the farming system.",
    "web page": "https://documents1.worldbank.org/curated/en/126251468331211716/pdf/634920PUB0Farm00Box0361517B0PUBLIC0.pdf",
    "Area covered": "Global",
    "Target user": "Policy maker; Practitioner; Researcher; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Livestock; Crops; Fisheries and aquaculture; Forestry",
    "Theme": "Farming Systems",
    "Type of resource": "Guide",
    "Resource Name": "Farming systems and food security in Africa",
    "Institution (lead)": "CGIAR",
    "Description": "This book describes the characteristics, trends, drivers of change and strategic priorities for each of Africa’s 15 farming systems and their main subsystems. It shows how a farming systems approach can be used to identify pathways to household food security and poverty reduction, and how strategic interventions need to be context appropriate.",
    "web page": "https://hdl.handle.net/10568/109061",
    "Area covered": "Africa",
    "Target user": "Policy maker; Practitioner; Researcher; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Impact",
    "Type of resource": "Data; Guide",
    "Resource Name": "Drought Catalogue",
    "Institution (lead)": "Global Facility for Disaster Reduction and Recovery/World Bank Group",
    "Description": "The Drought Catalogue is an online catalogue of the majority of worldwide available drought hazard risk tools, including drought indices and datasets and software products. A document is provided to guide policy makers, project managers, and professionals in assessing drought hazards and risks.",
    "web page": "https://droughtcatalogue.com/en/index.php/catalogue",
    "Area covered": "Global",
    "Target user": "Policy maker; Practitioner; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Water",
    "Theme": "Impact",
    "Type of resource": "Tool",
    "Resource Name": "Aqueduct",
    "Institution (lead)": "World Resources Institute",
    "Description": "Aqueduct is a tool for mapping current and future water related risks such as floods, droughts and stress. It enables decision-makers  to compare national and subnational water risk, and to identify current and future water risk to agriculture and food security.",
    "web page": "https://www.wri.org/aqueduct",
    "Area covered": "Global",
    "Target user": "Policy maker; Decision-maker; Practitioner; Project planner; Researcher"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Forestry; Water; Crops",
    "Theme": "Impact",
    "Type of resource": "Tool",
    "Resource Name": "Modelling System for Agricultural Impacts of Climate Change (MOSAICC)",
    "Institution (lead)": "Food and Agriculture Organization of the United Nations",
    "Description": "This tool provides an interdisciplinary approach to assessing climate change impacts at the national level. Its aim is to expand the knowledge of experts in climate change impacts and assist policy makers in developing informed adaptation strategies and development projects and investments.",
    "web page": "https://www.fao.org/in-action/mosaicc/en/",
    "Area covered": "",
    "Target user": "Researcher; Policy maker; Decision-maker; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Impact",
    "Type of resource": "Tool",
    "Resource Name": "ThinkHazard!",
    "Institution (lead)": "Global Facility for Disaster Reduction and Recovery/World Bank Group",
    "Description": "ThinkHazard! is a tool which enables non-specialists to identify the impacts of climatic disasters on new development projects. It considers the likelihood and severity (low, medium or high) of occurrence of hazards in a specific country, province or district. ThinkHazard! also provides resources on how best to mitigate the identified risks and gives a projected view on how the risks may change in the future.",
    "web page": "https://thinkhazard.org/en/",
    "Area covered": "Global",
    "Target user": "Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Crops; Livestock",
    "Theme": "Impact",
    "Type of resource": "Tool",
    "Resource Name": "Agricultural Model Intercomparison and Improvement Project (AgMIP)",
    "Institution (lead)": "Columbia University",
    "Description": "Agricultural Model Intercomparison and Improvement Project (AgMIP) harmonises analyses of agricultural systems at the local to global scales and looks at current and future impacts. It provides new methods for integrating stakeholder-informed scenarios into global and regional assessments of current and future agriculture and food systems.",
    "web page": "https://agmip.org/",
    "Area covered": "Global",
    "Target user": "Researcher; Policy maker; Decision-maker; Project planner; Researcher"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Impact; Adaptation; Vulnerability",
    "Type of resource": "Guide",
    "Resource Name": "Chapter 9: Africa. In: Climate change 2022: Impacts, adaptation and vulnerability",
    "Institution (lead)": "CGIAR",
    "Description": "This chapter of Working Group II's contribution to the Intergovernmental Panel on Climate Change (IPCC) Sixth Assessment Report assesses climate change impacts, risks, vulnerability and the enabling environments, barriers and options for adaptation and climate resilient development in Africa.",
    "web page": "https://cgspace.cgiar.org/handle/10568/122034",
    "Area covered": "Africa",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker; Researcher"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Agriculture; Disaster readiness",
    "Theme": "Risk",
    "Type of resource": "Tool",
    "Resource Name": "Climate and Disaster Risk Screening Tools",
    "Institution (lead)": "World Bank Group",
    "Description": "The World Bank’s Climate and Disaster Risk Screening Tools - Agriculture Projects screens agricultural projects for risks from climate variability and change, as well as geophysical disasters. The Rapid and In-Depth Screening Tools provide a systematic way to undertake due diligence and flag potential risks for projects in the agricultural sector. The tools guides the user via a step-by-step approach to identify potential risks at an early stage of project design.",
    "web page": "https://climatescreeningtools.worldbank.org/",
    "Area covered": "",
    "Target user": "Project planner; Policy maker; Development practitioner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Infrastructure",
    "Theme": "Risk",
    "Type of resource": "Guide",
    "Resource Name": "PIEVC Green Protocol — Integrating ecosystem-based adaptation into infrastructure climate risk assessments",
    "Institution (lead)": "Public Infrastructure Engineering Vulnerability Committee Global Partnership",
    "Description": "The PIEVC Green Protocol was developed to assist practitioners in understanding the risk of climate change to infrastructure whilst taking into account the wider socio-ecological system. It also addresses the potential impacts on the social-ecological system should infrastructure be disrupted or damaged.",
    "web page": "https://www.adaptationcommunity.net/wp-content/uploads/2022/11/2022giz-en-pievc-green-low-res.pdf",
    "Area covered": "",
    "Target user": "Project planner; Policy maker; Development practitioner; Decision-maker"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Natural environment",
    "Theme": "Risk",
    "Type of resource": "Guide",
    "Resource Name": "Climate risk assessment for ecosystem-based adaptation – a guidebook for planners and practitioners",
    "Institution (lead)": "German Development Cooperation",
    "Description": "This guidebook assists planners and practitioners in designing and implementing climate risk assessments in the context of ecosystem-based adaptation (EbA) projects. It provides a standardised approach to assess climate risks within social-ecological systems based on two application examples (river basin and coastal zone management).",
    "web page": "https://www.adaptationcommunity.net/wp-content/uploads/2018/06/giz-eurac-unu-2018-en-guidebook-climate-risk-asessment-eba.pdf",
    "Area covered": "",
    "Target user": "Project planner; Development practitioner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Risk",
    "Type of resource": "Tool",
    "Resource Name": "Caribbean Climate Online Risk and Adaptation tool (CCORAL)",
    "Institution (lead)": "Caribbean Community Climate Change",
    "Description": "Caribbean Climate Online Risk and Adaptation tool (CCORAL) is an online risk management toolbox with tools covering vulnerability assessment, risk assessment, adaptation option identification/appraisal, monitoring and evaluation, and awareness-raising. The risk screening tool allows the user to rapidly assess a project, plan or policy for climate-influence.",
    "web page": "http://ccoral.caribbeanclimate.bz/",
    "Area covered": "Caribbean",
    "Target user": "Policy maker; Decision-maker; Project planner; Development practitioner; Researcher"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Disaster readiness",
    "Theme": "Risk",
    "Type of resource": "Tool",
    "Resource Name": "Climate, Environment and Disaster Risk Reduction Integration Guidance (CEDRIG)",
    "Institution (lead)": "Swiss Agency for Development and Cooperation",
    "Description": "Climate, Environment and Disaster Risk Reduction Integration Guidance (CEDRIG) is an open-access online tool integrating climate, environment and disaster risk reduction (DRR) to assess climate risks and/or impacts of projects, plans or programmes. CEDRIG uses an integrated approach to assess the risks for, and the unintended potential negative impacts of, a new strategy, programme or project. It provides three different types of assessment - 'light' for rapid risk and impact screening, and 'strategic' and 'operational' assessments for a detailed approach and integration into existing or planned strategies or programmes.",
    "web page": "www.cedrig.org",
    "Area covered": "",
    "Target user": "Policy maker; Decision-maker; Project planner; Development practitioner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Natural environment; Energy; Health; Water; Agriculture; Economic",
    "Theme": "Risk",
    "Type of resource": "Data",
    "Resource Name": "Climate Risk Country Profiles",
    "Institution (lead)": "World Bank Group",
    "Description": "The Climate Risk Country Profiles provide a high-level assessment of climate risks for countries globally. They inform decision-makers of the potential for increasing and emerging risks in accordance with different climate futures. The profiles describe climate change impacts to key sectors such as agriculture, energy and health.",
    "web page": "Climate Risk Country Profiles | Climate Change Knowledge Portal (worldbank.org)",
    "Area covered": "Global",
    "Target user": "Development practitioner; Policy maker; Decision-maker"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Risk",
    "Type of resource": "Guide",
    "Resource Name": "Economics of climate adaptation (ECA) - Guidebook for practitioners",
    "Institution (lead)": "United Nations University",
    "Description": "This document presents a climate risk assessment approach to inform climate adaptation investments.  The approach combines risk assessment, adaptation measures and risk transfer and its results allow for the identification of cost-effective climate adaptation measures for a variety of projects and sectors.",
    "web page": "https://climate-adapt.eea.europa.eu/en/metadata/tools/climada/unu_2016_no6_guidebook_economics-of-climate-adaptation_en.pdf/view",
    "Area covered": "",
    "Target user": "Development practitioner; Decision-maker"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Disaster readiness",
    "Theme": "Risk",
    "Type of resource": "Tool",
    "Resource Name": "Risk Stress Test Tool",
    "Institution (lead)": "World Bank Group",
    "Description": "The Risk Stress Test (RiST) tool is an Excel-based tool which has been developed to assist with the stress testing analysis described in the methodological note 'Integrating Climate Change and Natural Disasters in the Economic Analysis of Projects: A disaster and climate risk stress methodology'. The tool highlights risks to project outcomes in the long term and identifies risks resulting from changing climate conditions, impacts of natural disasters, and changes in the frequency and intensity of natural disasters.",
    "web page": "https://www.worldbank.org/en/topic/climatechange/brief/risk-stress-test-tool",
    "Area covered": "",
    "Target user": "Decision-maker;  Development practitioner; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Crops",
    "Theme": "Risk",
    "Type of resource": "Guide",
    "Resource Name": "Climate risk analyses in Southern and Central Africa",
    "Institution (lead)": "International Fund for Agricultural Development",
    "Description": "A series of eight climate risk analysis reports covering countries in Southern and Central Africa namely, Angola, Lesotho, Malawi, Mozambique, Rwanda, Uganda, Zambia and Zimbabwe. The reports identify staple crops grown in each country and the suitability of the crops with projected changes in rainfall and temperature through to 2050. The reports focus on crops grown by subsistence farmers and make climate change projections at a provincial production level.",
    "web page": "https://www.ifad.org/en/web/knowledge/-/climate-change-and-future-crop-suitability-in-east-and-southern-africa?p_l_back_url=%2Fen%2Fsearch%3Fq%3Dclimate%2Brisk",
    "Area covered": "Southern and Central Africa",
    "Target user": "Decision-maker;  Development practitioner; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Crops",
    "Theme": "Risk",
    "Type of resource": "Tool",
    "Resource Name": "Climate Adaptation in Rural Development (CARD) Assessment Tool",
    "Institution (lead)": "International Fund for Agricultural Development",
    "Description": "The Climate Adaptation in Rural Development (CARD) Assessment Tool provides a platform for examining how climate change is affecting and is likely to affect the yields of 17 key crops across the 11 IFAD regions through to 2050. The tool is designed to provide quantitative data concerning climate risks faced by agricultural and rural development investments and strategies including economic and financial analyses.",
    "web page": "https://www.ifad.org/en/web/knowledge/-/publication/climate-adaptation-in-rural-development-card-assessment-tool?p_l_back_url=%2Fen%2Fweb%2Fknowledge%2Ftools%3Fmode%3Dsearch%26catTopics%3D39130752",
    "Area covered": "Global",
    "Target user": "Policy maker; Development practitioner; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Crops; Livestock",
    "Theme": "Risk",
    "Type of resource": "Tool",
    "Resource Name": "Self-evaluation and Holistic Assessment of climate Resilience of farmers and Pastoralists (SHARP+)",
    "Institution (lead)": "Food and Agriculture Organization of the United Nations",
    "Description": "The tool acknowledges that family farmers and pastoralists can provide valuable insight when planning climate resilient pathways in Sub-Saharan countries. The tool gives a holistic assessment of farmers climate resilience at the household level, taking socioeconomic, environmental and agronomic factors into account. The assessment identifies areas of weakness in need of intervention. The information generated can then be used to inform the design of project activities, to monitor and evaluate the resilience and adaptive capacities of the targeted rural communities.",
    "web page": "https://www.fao.org/in-action/sharp/en/",
    "Area covered": "Sub-Saharan Africa",
    "Target user": "Development practitioner; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Disaster readiness",
    "Theme": "Risk",
    "Type of resource": "Tool",
    "Resource Name": "Participatory Assessment of Climate and Disaster Risk (PACDR)",
    "Institution (lead)": "Brot für die Welt",
    "Description": "The Participatory Assessment of Climate and Disaster Risk (PACDR) tool comprises seven modules which when combined allow users to incorporate climate and disaster risk into the planning of development projects at a community level. The tool combines information from the local community as well as scientific knowledge related to climate change in order to identify context specific risks. It guides the user through the community assessment of both climate and disaster risks and opportunities. The assessment can then inform ongoing or planned projects and programmes and community planning.",
    "web page": "https://pacdr.net/",
    "Area covered": "",
    "Target user": "Development practitioner; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Crops",
    "Theme": "Risk",
    "Type of resource": "Tool",
    "Resource Name": "The Assessment of Impacts and Risks of Climate Change on Agriculture (AIRCCA) Model",
    "Institution (lead)": "National Autonomous University of Mexico/University of Amsterdam",
    "Description": "This tool is an Integrated Assessment Model (IAM) for producing impact scenarios and risk metrics for the four main Intergovernmental Panel on Climate Change (IPCC) emission scenarios. The tool allows decision-makers and stakeholders to conduct global assessments on the climate change impacts on maize, wheat and rice production.",
    "web page": "https://sites.google.com/view/aircc-lab",
    "Area covered": "Global",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Agriculture; Food security; Disaster readiness",
    "Theme": "Risk",
    "Type of resource": "Tool",
    "Resource Name": "Africa RiskView",
    "Institution (lead)": "African Risk Capacity Group",
    "Description": "Africa RiskView was developed to translate globally-available rainfall data, crop parameters and livelihood information into food security outlooks. It calculates the cost of aid responses to improve financial planning and resource allocation. It also provides a technical support package  for a potential pan-African Disaster Risk Pool, allowing participating countries to immediately access funds when faced with an extreme climatic event.",
    "web page": "www.africariskview.org",
    "Area covered": "Africa",
    "Target user": "Policy maker; Decision-maker"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Risk",
    "Type of resource": "Data",
    "Resource Name": "Risk Information Exchange (RiX)",
    "Institution (lead)": "United Nations Office for Disaster Risk Reduction",
    "Description": "Risk Information Exchange (RiX) aggregates over 600 global and national risk datasets spanning hazard, vulnerability, exposure, and climate change. Key information is geo-tagged and mapped in a visualisation tool. It aims to harmonise risk information to enable risk analyses by governments, the private sector, and other actors for improved decision making and resilience building.",
    "web page": "https://rix.undrr.org/",
    "Area covered": "Global",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker; Researcher"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Agriculture",
    "Theme": "Risk; Gender",
    "Type of resource": "Guide",
    "Resource Name": "Platform for Agricultural Risk Management (PARM)",
    "Institution (lead)": "Platform for Agricultural Risk Management",
    "Description": "The Platform for Agricultural Risk Management aims to share knowledge and build capacity in agricultural risk management to improve vulnerable rural households' abilities to contribute to better agricultural systems. The documents guide on how to conduct an Agricultural Value Chain Risk Assessment Study (AVC-RAS) at the national level in a gender responsive way.",
    "web page": "https://www.p4arm.org/document-type/risk-assessment/",
    "Area covered": "Africa",
    "Target user": "Policy maker; Development practitioner; Decision-maker; Project planner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Vulnerability",
    "Type of resource": "Data",
    "Resource Name": "Notre Dame-Global Adaptation Index (ND-GAIN)",
    "Institution (lead)": "University of Notre Dame",
    "Description": "The Notre Dame-Global Adaptation Index (ND-GAIN) Country Index indicates a country´s vulnerability to climate change and readiness to improve resilience, as well as the ND-Gain, a third index computed by subtracting the vulnerability score from the readiness score. This comprehensive index considers large amounts of data, covering a long time span and has a global extent. It aims to assist governments, businesses and communities to better prioritise investments for a more efficient response to global challenges.",
    "web page": "https://gain.nd.edu/our-work/country-index/",
    "Area covered": "Global",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker;"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Disaster readiness",
    "Theme": "Vulnerability",
    "Type of resource": "Data",
    "Resource Name": "INFORM",
    "Institution (lead)": "European Commission",
    "Description": "INFORM provides a suite of quantitative, analytical products to support decision-making on humanitarian crises and disasters. The INFORM Risk Index provides risk assessments on a national and sub-national (province, municipality, village) level, giving indicators for hazard, exposure, vulnerability and lack of coping capacity.",
    "web page": "https://drmkc.jrc.ec.europa.eu/inform-index/INFORM-Risk",
    "Area covered": "Global",
    "Target user": "Policy maker; Decision-maker"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Water",
    "Theme": "Vulnerability",
    "Type of resource": "Data",
    "Resource Name": "AQUASTAT",
    "Institution (lead)": "Food and Agriculture Organization of the United Nations",
    "Description": "AQUASTAT allows users to access country statistics on water resources, water uses and agricultural water management. Other complementary databases available include irrigated crop calendars, sub-national irrigation areas databases,  a database on dams and reservoirs and a water and agriculture- related institutions database.",
    "web page": "https://www.fao.org/aquastat/en/databases/",
    "Area covered": "Africa; Asia; Latin America; Caribbean",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker;"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Vulnerability",
    "Type of resource": "Guide - framework",
    "Resource Name": "A framework for climate change vulnerability assessments",
    "Institution (lead)": "German Development Cooperation",
    "Description": "The framework gives a structured approach to assessing vulnerability to climate change. It provides a selection of methods and tools for assessing components of vulnerability to enable practitioners and policy makers to include climate change in their decision making. It uses both bottom up and top down approaches to assess climate change vulnerability.",
    "web page": "https://www.weadapt.org/sites/weadapt.org/files/legacy-new/knowledge-base/files/5476022698f9agiz2014-1733en-framework-climate-change.pdf",
    "Area covered": "",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker;"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Multi sector",
    "Theme": "Vulnerability; Adaptation",
    "Type of resource": "Data",
    "Resource Name": "Climate Change Profiles",
    "Institution (lead)": "Government of the Netherlands",
    "Description": "The profiles provide information on countries' vulnerabilities to climate change, their policies and commitments in place, and the climate change interventions which are financed with international assistance. The profiles aim to assist with incorporating climate actions into development cooperation policies and activities.",
    "web page": "https://www.government.nl/ministries/ministry-of-foreign-affairs/documents/publications/2019/02/05/climate-change-profiles",
    "Area covered": "Africa",
    "Target user": "Decision-maker; Development practitioner"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Agriculture",
    "Theme": "Vulnerability; Gender",
    "Type of resource": "Guide - methodology",
    "Resource Name": "Effectively targeting climate investments: A methodology for mapping climate–agriculture–gender inequality hotspots",
    "Institution (lead)": "CGIAR",
    "Description": "This document provides a methodology for identifying, ranking and mapping climate–agriculture–gender inequality hotspots. Climate–agriculture–gender inequality hotspots are geographical areas where high levels of climate hazards intersect with high levels of women’s participation in agriculture (exposure) and high levels of women’s vulnerability due to prevailing gender inequalities.",
    "web page": "https://gender.cgiar.org/news/identifying-climate-agriculture-gender-inequality-hotspots-can-help-target-investments-and",
    "Area covered": "Global",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker;"
  },
  {
    "Project phase/component": "1. Initial Climate Risk Assessment",
    "Target sector": "Crops; Forestry; Livestock; Natural environment; Energy; Economic; Health",
    "Theme": "Vulnerability; Gender",
    "Type of resource": "Data",
    "Resource Name": "World Bank Open Data",
    "Institution (lead)": "World Bank Group",
    "Description": "World Bank Open Data is a database containing relevant information on agriculture and rural development, climate change, education, environment, gender, poverty and social development, amongst other topics, which can be used to understand the vulnerabilities of a chosen country or region to climate change.",
    "web page": "https://data.worldbank.org/",
    "Area covered": "Global",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker; Researcher"
  },
  {
    "Project phase/component": "2. Response prioritization",
    "Target sector": "Fisheries and aquaculture; Forestry; Crops; Livestock",
    "Theme": "Adaptation",
    "Type of resource": "Tool",
    "Resource Name": "Adaptation Framework Tool",
    "Institution (lead)": "International Fund for Agricultural Development",
    "Description": "This framework enables project designers to assess cost-benefit, climate risk, farmer capacity, mitigation benefits and biodiversity using a multi-criteria analysis system. The system then selects the most effective adaptation measures by analysing climate change risks and impacts. The results produced by the framework then give a rationale directed at mobilising climate finance.",
    "web page": "https://www.ifad.org/en/web/knowledge/-/publication/adaptation-framework-tool?p_l_back_url=%2Fen%2Fweb%2Fknowledge%2Ftools%3Fmode%3Dsearch%26catTopics%3D39130752",
    "Area covered": "Africa",
    "Target user": "Project planner; Development practitioner"
  },
  {
    "Project phase/component": "2. Response prioritization",
    "Target sector": "Agriculture",
    "Theme": "Adaptation",
    "Type of resource": "Guide",
    "Resource Name": "Climate-smart agriculture investment plan development guide: From concept to action",
    "Institution (lead)": "World Bank Group",
    "Description": "This guide assists the user in developing a climate-smart agriculture investment plan (CSAIP). The process involves a Initial Climate Risk Assessment, the prioritisation of potential CSA investments, and the identification of means for implementing and monitoring project investments. Stakeholder engagement and capacity development are key components of the process. The result is a set of evidence-based and context appropriate interventions for investors to consider.",
    "web page": "https://hdl.handle.net/10568/106262",
    "Area covered": "",
    "Target user": "Decision-maker; Policy maker; Development practitioner; Project planner; Researcher"
  },
  {
    "Project phase/component": "2. Response prioritization",
    "Target sector": "Agriculture",
    "Theme": "Adaptation",
    "Type of resource": "Guide - journal article, framework",
    "Resource Name": "Cost-benefit analysis of prioritized climate-smart agricultural practices among smallholder farmers: evidence from selected value chains across Sub-Saharan Africa",
    "Institution (lead)": "The Alliance of Bioversity International and the International Centre for Tropical Agriculture",
    "Description": "This article provides a multi-dimensional framework to assess prioritised climate-smart agricultural (CSA) practices adopted by smallholder farmers across different value chains in Sub-Saharan Africa (the selected value chains were restricted to those specific to the GIZ priorities in each country).  The economic feasibility of the practices were evaluated using cost-benefit analysis (CBA). The combination of the CSA prioritisation framework and CBA ensures the selection of the most viable and cost-effective CSA practices for resource allocation.",
    "web page": "https://www.sciencedirect.com/science/article/pii/S2405844022005163?via%3Dihub",
    "Area covered": "Sub-Saharan Africa:\nBenin, Burkina Faso, Cameroon, Ivory Coast, Mali, Mozambique, Togo, and Tunisia",
    "Target user": "Decision-maker; Development practitioner; Project planner; Investor"
  },
  {
    "Project phase/component": "2. Response prioritization",
    "Target sector": "Crops; Livestock",
    "Theme": "Adaptation",
    "Type of resource": "Guide - journal article, methodology",
    "Resource Name": "Climate change adaptation options to inform planning of agriculture and food systems in The Gambia: A systematic approach for stocktaking",
    "Institution (lead)": "CGIAR",
    "Description": "This journal article provides a systematic approach for screening adaptation options. The approach was used to assess 24 adaptation options from The Gambia and West Africa Region. The adaptation options were assessed according to how they contribute to the three pillars of climate-smart agriculture (CSA).  The approach can be applied to assess adaptation options in other least developed countries in the West African region.",
    "web page": "https://www.frontiersin.org/articles/10.3389/fsufs.2022.834867/full",
    "Area covered": "The Gambia; West Africa",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker"
  },
  {
    "Project phase/component": "2. Response prioritization",
    "Target sector": "Livestock; Crops",
    "Theme": "Adaptation; Gender",
    "Type of resource": "Tool",
    "Resource Name": "Climate-Smart Agriculture Rapid Appraisal (CSA-RA) Tool",
    "Institution (lead)": "The Alliance of Bioversity International and the International Centre for Tropical Agriculture",
    "Description": "The CSA-RA is a participatory, bottom-up approach (that can be applied across multiple scales and actors) used to understand CSA priorities, synergies and trade-offs important in the local context, that can then be used to inform regional, national, and international public and private sector investments. Key operational staff in development agencies and national governments who design lending and climate finance projects as well as practitioners who implement CSA, need access to these tools. The CSA-RA was developed to address and fill these gaps by identifying the key challenges in smallholder agricultural systems within different contexts, and develop a participatory prioritization of a set of CSA practices that can respond to such challenges for targeted implementation. The CSA-RA is rigorous but easy to use by various stakeholders, and can be adapted to fit any agricultural system. As such, the CSA-RA (i) justifies the need for context specific CSA targeting that operationalizes the CSA plans and other development strategies and (ii) provides criteria and process that guide identification of appropriate CSA options for local contexts.",
    "web page": "Step-by Step Guide: https://www.ifad.org/documents/38714170/39144386/csa-ra.pdf/dae31d53-8d2b-4ec1-a89e-4a39ab046f89\nPeer reviewed article with case studies:\nhttps://cgspace.cgiar.org/handle/10568/75715",
    "Area covered": "Global",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker; Researcher"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Energy; Water; Crops; Livestock; Fisheries and aquaculture; Forestry",
    "Theme": "Adaptation",
    "Type of resource": "Data",
    "Resource Name": "Evidence for Resilient Agriculture",
    "Institution (lead)": "CGIAR",
    "Description": "The Evidence for Resilient Agriculture (ERA) platform provides data and tools on the performance of agricultural technologies to assist users in making informed decisions. It provides evidence on the effects of changing from one technology to another, looking specifically at productivity, system resilience and climate change mitigation.",
    "web page": "https://era.ccafs.cgiar.org/",
    "Area covered": "Africa",
    "Target user": "Policy maker; Project planner; Development practitioner; Extension agent"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "",
    "Theme": "Adaptation",
    "Type of resource": "Tool",
    "Resource Name": "The Adaptation Support Tool",
    "Institution (lead)": "European Commission/European Environment Agency",
    "Description": "This is a six-step tool for assisting policy makers at the national level in developing, implementing and monitoring climate change adaptation strategies and plans. It also supports sub-national actors in preparing for, developing, implementing and monitoring and evaluating adaptation strategies.",
    "web page": "https://climate-adapt.eea.europa.eu/knowledge/tools/adaptation-support-tool",
    "Area covered": "",
    "Target user": "Policy maker; Decision-maker"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Crop; Livestock",
    "Theme": "Adaptation",
    "Type of resource": "Guide - training manual",
    "Resource Name": "Weather /climate manual for agriculture",
    "Institution (lead)": "CGIAR",
    "Description": "This manual provides basic information on weather and climate from a global to national (Ghana) level. It describes Ghana's farming systems and the effects of changing weather and climate on agricultural production.",
    "web page": "https://hdl.handle.net/10568/127185",
    "Area covered": "Ghana",
    "Target user": "Development practitioner; Project planner; Extension agent"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Crops",
    "Theme": "Adaptation",
    "Type of resource": "Guide - journal article",
    "Resource Name": "How using weather and climate information services may impact farm productivity and technical efficiency: Evidence from cowpea and sesame producers in Burkina Faso",
    "Institution (lead)": "The Alliance of Bioversity International and the International Center for Tropical Agriculture",
    "Description": "This paper provides evidence on the impact of weather and climate information services (WCIS) on the productivity and incomes from cowpea and sesame cultivation in Burkina Faso.",
    "web page": "https://hdl.handle.net/10568/126803",
    "Area covered": "Burkina Faso",
    "Target user": "Development practitioner; Project planner; Researcher; Policy maker"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Adaptation",
    "Type of resource": "Guide - journal article",
    "Resource Name": "Country-specific challenges to improving effectiveness, scalability and sustainability of agricultural climate services in Africa",
    "Institution (lead)": "International Research Institute for Climate and Society",
    "Description": "This paper considers the national contexts of six different African countries (Ethiopia, Ghana, Kenya, Mali, Senegal, Zambia) when developing strategies to make agro-climatic services scalable and sustainable. It addresses the key strengths and weaknesses of the countries' agro-climatic services; the different needs of each country; and ways to strengthen the contribution of the services to the agricultural sectors.",
    "web page": "https://hdl.handle.net/10568/121904",
    "Area covered": "Sub-Saharan Africa",
    "Target user": "Development practitioner; Policy maker; Decision-maker"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Adaptation",
    "Type of resource": "Guide - journal article",
    "Resource Name": "Farmers’ demand for climate information services: A systematic review",
    "Institution (lead)": "University of Ghana",
    "Description": "This review assesses farmers' demand for CIS in the Economic Community of West African States (ECOWAS). It highlights the key characteristics of the CIS which is sought and the drivers behind the demand.",
    "web page": "https://hdl.handle.net/10568/126511",
    "Area covered": "West Africa",
    "Target user": "Development practitioner; Decision-maker"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Crops",
    "Theme": "Adaptation",
    "Type of resource": "Guide - training manual",
    "Resource Name": "Training Manual on Bundled Climate Smart Agriculture, Climate Information Services and One-Health Technologies for Priority Value Chains",
    "Institution (lead)": "CGIAR",
    "Description": "This manual is intended for trainers of trainees and extension agents to enhance agricultural production in a sustainable way. The manual comprises 11 modules covering the following topics: climate information services; gender and social inclusion; the concept of OneHealth; biological treatment of soils and seeds; sustainable management interventions for sweet potato, yams, maize and cowpea; aeroponics and hydroponics; soil health management; improved tillage; and water-smart technologies.",
    "web page": "https://hdl.handle.net/10568/126752",
    "Area covered": "Ghana",
    "Target user": "Development practitioner; Project planner; Extension agent"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Adaptation",
    "Type of resource": "Guide - training manual",
    "Resource Name": "Translating climate-smart agriculture policies into action: A guidebook for operationalizing climate-smart agriculture into local action planning",
    "Institution (lead)": "The Alliance of Bioversity International and the International Center for Tropical Agriculture",
    "Description": "Implementing national policy as local-level action is challenging and requires knowledgeable technical officers and policy makers. This guidebook is based on capacity building workshops for subnational agricultural officers in Kenya. It provides lessons, activities and a day-by-day agenda for conducting  similar workshops, the outputs of which include a climate-smart agriculture (CSA) action plan and a project concept.",
    "web page": "https://hdl.handle.net/10568/114409",
    "Area covered": "Kenya",
    "Target user": "Project planner; Development practitioner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Adaptation",
    "Type of resource": "Guide",
    "Resource Name": "Climate-Smart Agriculture Sourcebook",
    "Institution (lead)": "Food and Agriculture Organization of the United Nations",
    "Description": "The Climate-Smart Agriculture (CSA) Sourcebook provides information on the concept of CSA to guide policy makers, programme managers, sectoral experts, academics, extensionists, and practitioners in making agriculture (crops, livestock, fisheries and forestry) more sustainable and productive, whilst simultaneously responding to climate-related challenges.",
    "web page": "https://www.fao.org/climate-smart-agriculture-sourcebook/en/",
    "Area covered": "Global",
    "Target user": "Policy maker; Project planner; Project implementer; Academic; Extension agent; Development practitioner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Crops",
    "Theme": "Adaptation",
    "Type of resource": "Guide - journal article",
    "Resource Name": "Scaling climate resilient seed systems through SMEs in Eastern and Southern Africa: Challenges and opportunities",
    "Institution (lead)": "International Livestock Research Institute",
    "Description": "This article assesses the role of small and medium sized agri-businesses in scaling climate resilient seed systems using inclusive business models in Eastern and Southern Africa. It underscores the need for inclusive business models which are more compatible with local farming contexts so as to support equitable and sustainable transitions at scale.",
    "web page": "https://hdl.handle.net/10568/120469",
    "Area covered": "Eastern and Southern Africa",
    "Target user": "Development practitioner; Project planner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Crops; Livestock; Natural environment; Forestry",
    "Theme": "Adaptation",
    "Type of resource": "Guide",
    "Resource Name": "Ecosystem-based adaptation",
    "Institution (lead)": "United Nations Environment Programme",
    "Description": "This website provides a briefing note series, links to toolkits, additional resources and case studies on ecosystem-based adaptation (EbA). It also provides access to information on over 45 EbA projects located globally.",
    "web page": "https://www.unep.org/explore-topics/climate-action/what-we-do/climate-adaptation/ecosystem-based-adaptation",
    "Area covered": "",
    "Target user": "Policy maker; Project planner; Extension agent; Development practitioner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Crops; Livestock",
    "Theme": "Adaptation",
    "Type of resource": "Guide",
    "Resource Name": "Ecosystem-based adaptation in agriculture",
    "Institution (lead)": "United Nations Environment Programme",
    "Description": "This document introduces the concept of ecosystem-based adaptation (EbA) and gives relevant agricultural practices for climate change related environmental, economic and social impacts. It provides two case studies (Cambodia and Sudan) on EbA application and links to further resources.",
    "web page": "https://wedocs.unep.org/handle/20.500.11822/40405",
    "Area covered": "",
    "Target user": "Project planner; Extension agent; Development practitioner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "",
    "Theme": "Adaptation",
    "Type of resource": "Guide",
    "Resource Name": "Adaptation Community Platform",
    "Institution (lead)": "German Development Cooperation",
    "Description": "Adaptationcommunity.net provides information on applying approaches, methods and tools that facilitate the planning and implementation of adaptation action. Information, online sessions and trainings are offered on nine topics comprising: climate services, comprehensive climate risk management, the National Adaptation Plan (NAP) process, mainstreaming of adaptation, ecosystem-based adaptation (EbA), agroecology, climate change and migration, private sector adaptation and monitoring and evaluation.",
    "web page": "https://www.adaptationcommunity.net/",
    "Area covered": "",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Livestock; Crops; Fisheries and aquaculture; Forestry",
    "Theme": "Adaptation",
    "Type of resource": "Guide",
    "Resource Name": "Ecosystem-based adaptation in the agriculture sector",
    "Institution (lead)": "Food and Agriculture Organization of the United Nations",
    "Description": "This document provides key nature-based solutions for adaptation in the food and agricultural sector as given in countries’ nationally determined contributions (NDCs). Best case practices showcasing the solutions can be viewed in presentations and videos here: http://www.fao.org/in-action/kore/news-and-events/news-details/en/c/1052870/",
    "web page": "https://www.fao.org/3/cb0651en/CB0651EN.pdf",
    "Area covered": "",
    "Target user": "Policy maker; Project planner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Crops; Livestock",
    "Theme": "Adaptation",
    "Type of resource": "Guide",
    "Resource Name": "Conservation Agriculture",
    "Institution (lead)": "Food and Agriculture Organization of the United Nations",
    "Description": "This website explains the concept of conservation agriculture, its benefits, how to implement it, case studies describing its application and links to further resources.",
    "web page": "https://www.fao.org/conservation-agriculture/overview/what-is-conservation-agriculture/en/",
    "Area covered": "Global",
    "Target user": "Policy maker; Project planner; Extension agent; Development practitioner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Adaptation",
    "Type of resource": "Guide - journal article",
    "Resource Name": "A meta-analysis of the adoption of agricultural technology in Sub-Saharan Africa",
    "Institution (lead)": "International Fund for Agricultural Development",
    "Description": "This paper presents a meta-analysis exploring the reasons for farmers adopting or not adopting new agricultural technologies. It gives the reader guidance on how to design effective agricultural interventions to reduce  poverty and hunger.",
    "web page": "https://hdl.handle.net/10568/120051",
    "Area covered": "Sub-Saharan Africa",
    "Target user": "Development practitioner; Project planner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Crops",
    "Theme": "Adaptation; Gender",
    "Type of resource": "Guide - training manual",
    "Resource Name": "Climate-smart agriculture training manual for training of trainers: Promoting adoption of pigeon pea, sorghum and pearl millet",
    "Institution (lead)": "CGIAR",
    "Description": "This training manual provides information for government extension officers and lead farmers to enable them to train farmers on climate-smart agriculture (CSA) technologies, innovations and management practices. The manual enables an understanding of the concept of CSA and associated technologies and practices. It teaches of context specific CSA application through adapting technologies, practices and solutions to suit different locations in Kenya. It emphasises the importance of gender and social inclusion.",
    "web page": "https://hdl.handle.net/10568/127282",
    "Area covered": "Kenya",
    "Target user": "Development practitioner; Project planner; Extension agent"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Adaptation; Gender",
    "Type of resource": "Guide - journal article",
    "Resource Name": "Gender perspectives of ICT utilization in agriculture and climate response in West Africa: A review",
    "Institution (lead)": "The Alliance of Bioversity International and the International Center for Tropical Agriculture",
    "Description": "This article identifies knowledge gaps on gender perspectives of information and communications technology (ICT) application in the agricultural sector in West Africa. Findings indicate that ICTs can contribute to positive gender outcomes in climate resilient agriculture provided the enabling environment allows women to access the inputs and resources needed to implement agro-advisories.",
    "web page": "https://hdl.handle.net/10568/121967",
    "Area covered": "West Africa",
    "Target user": "Development practitioner; Project planner; Researcher; Policy maker; Decision-maker"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "",
    "Theme": "Adaptation; Gender",
    "Type of resource": "Guide - journal article",
    "Resource Name": "Synergies and trade-offs between climate change adaptation options and gender equality",
    "Institution (lead)": "multiple authors",
    "Description": "This journal article discusses the gender responsiveness of climate change adaptation options. It discusses policy actions and priorities, such as gender-sensitive project design, that can reduce/eliminate some of the trade-offs that adaptation can have on gender equality. It provides policy and action priorities for adaptation projects to improve gender equality.",
    "web page": "https://www.nature.com/articles/s41599-022-01266-6",
    "Area covered": "",
    "Target user": "Policy maker; Project planner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Adaptation; Gender",
    "Type of resource": "Guide",
    "Resource Name": "A gender-responsive approach to climate-smart agriculture: Evidence and guidance for practitioners",
    "Institution (lead)": "Food and Agriculture Organization of the United Nations",
    "Description": "This practice brief  explains how to take into account the gender gap in agriculture in the development of site-specific climate-smart agriculture (CSA) practices through the adoption of a gender-responsive approach. Criteria are given to assist in determining whether a gender-responsive approach has  been used in the implementation of CSA practices.",
    "web page": "https://www.fao.org/3/be879e/be879e.pdf",
    "Area covered": "",
    "Target user": "Project planner; Project implementer"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Crops",
    "Theme": "Adaptation; Gender",
    "Type of resource": "Guide - training manual",
    "Resource Name": "Climate information services training manual for training of trainers: Promoting adoption of pigeon pea, sorghum and pearl millet",
    "Institution (lead)": "CGIAR",
    "Description": "This training manual is to be used to develop the capacities of agricultural extension officers, lead farmers, small and medium-sized enterprises and farmer producer organisations to scale climate information services (CIS). The manual covers the following topics: climate change and variability; interpreting and applying weather information; indigenous knowledge in weather prediction; communication of weather forecasts; and a gendered approach to CIS.",
    "web page": "https://hdl.handle.net/10568/127285",
    "Area covered": "Kenya",
    "Target user": "Development practitioner; Project planner; Extension agent"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Adaptation; Gender",
    "Type of resource": "Guide -checklist",
    "Resource Name": "Checklist: Gender-inclusive actionable agro-advisories",
    "Institution (lead)": "CGIAR",
    "Description": "This checklist assists in developing agro-advisories (forecast and forecast-based advice) that are gender-inclusive and useful for both men and women farmers. It presents five indicators to determine the actionability of such agro-advisories: i) What information is available?; ii) If it is available, is it accessible? iii) If available and accessible, is it on time? iv) If available, accessible, and on time, can end-users understand it? v) If available, accessible, timely, and understandable, is it useful?",
    "web page": "https://cgspace.cgiar.org/handle/10568/106790",
    "Area covered": "",
    "Target user": "Development practitioner; Project planner; Decision-maker"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Adaptation; Gender",
    "Type of resource": "Guide - journal article, framework",
    "Resource Name": "Framework for Incorporating Gender Equality and Social Inclusion (GESI) Elements in Climate Information Services (CIS)",
    "Institution (lead)": "multiple authors",
    "Description": "This article provides a gender equality and social inclusion (GESI) framework for climate information services (CIS). It describes how women need to be included in the design and development of agricultural technologies or they may be excluded from the benefits. Recommendations are given for five indicator areas: (1) gender targeting by intentional design; (2) collection of sex-disaggregated data; (3) conduct an analysis of the sex-disaggregated data; (4) dissemination of the technological options; and (5) conduct continuous monitoring of gender and ongoing empowerment evaluation. The framework is applicable to three development areas - agricultural data; training on climate information services; and flood and drought indicators.",
    "web page": "https://www.mdpi.com/2071-1050/15/1/190",
    "Area covered": "Zambia",
    "Target user": "Development practitioner; Project planner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Livestock; Crops",
    "Theme": "Adaptation; Vulnerability",
    "Type of resource": "Guide",
    "Resource Name": "Green Innovation Centres for the agriculture and food sector",
    "Institution (lead)": "German Development Cooperation",
    "Description": "The series of reports provide climate and vulnerability analyses of Green Innovation Centre (GIC) target commodity value chains. The reports identify climate-related vulnerabilities, hazards, and adaptation options. Climate hazards and crop suitability modelling indicate potential future scenarios under climate change and prioritised adaptation solutions are given.",
    "web page": "https://www.giz.de/en/worldwide/32209.html",
    "Area covered": "Africa, Asia, South East Asia",
    "Target user": "Policy maker; Investor; Project planner; Extension agent; Practitioner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Risk",
    "Type of resource": "Guide - training manual",
    "Resource Name": "Climate Risk Management in Agricultural Extension (CRMAE) Reference Guide",
    "Institution (lead)": "International Research Institute for Climate and Society",
    "Description": "This guide provides reference materials for the Climate Risk Management in Agricultural Extension (CRMAE) course in Ethiopia. The guide is targeted to extension staff and development practitioners but is also appropriate for use by non-governmental organisations (NGOs) or community-based organisations (CBOs) which work with farmers. It provides practical tools to assess climate risks, to use weather and climate information for informed agricultural decision making, to effectively communicate climate information with farmers, and to integrate climate services into agricultural extension.",
    "web page": "https://cgspace.cgiar.org/handle/10568/127001",
    "Area covered": "Ethiopia",
    "Target user": "Extension agent; Development practitioner; Project planner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Risk",
    "Type of resource": "Guide - training manual",
    "Resource Name": "Climate Risk Management in Agricultural Extension (CRMAE) Handbook",
    "Institution (lead)": "International Research Institute for Climate and Society",
    "Description": "This handbook forms part of the materials for the Climate Risk Management in Agricultural Extension  (CRMAE) course in Ethiopia. The handbook is targeted to extension staff and development practitioners but is also appropriate for use by non-governmental organisations or community-based organisations which work with farmers. It provides practical tools to assess climate risks, to use weather and climate information for informed agricultural decision making, to effectively communicate climate information with farmers, and to integrate climate services into agricultural extension.",
    "web page": "https://academiccommons.columbia.edu/doi/10.7916/47sy-bq17",
    "Area covered": "Ethiopia",
    "Target user": "Extension agent; Development practitioner; Project planner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Risk",
    "Type of resource": "Guide - training manual",
    "Resource Name": "Climate Risk Management in Agricultural Extension (CRMAE) Facilitators’ Guide",
    "Institution (lead)": "International Research Institute for Climate and Society",
    "Description": "The Climate Risk Management in Agricultural Extension (CRMAE) Facilitators’ Guide was developed to assist facilitators in implementing the CRMAE course. It guides the facilitators with step-by-step instructions for each of the four modules and includes an agenda for each session as well as presentations, activities, and worksheets.",
    "web page": "https://hdl.handle.net/10568/127006",
    "Area covered": "Ethiopia",
    "Target user": "Development practitioner; Project planner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Multi sector",
    "Theme": "Risk",
    "Type of resource": "Guide -methodology",
    "Resource Name": "The Risk Supplement to the Vulnerability Sourcebook",
    "Institution (lead)": "German Development Cooperation",
    "Description": "The Fifth IPCC Assessment Report (AR5) replaced the previous concept of vulnerability with the concept of risk of climate change impacts. This risk concept has been adopted from the approach and practices of risk assessment in the disaster risk reduction community. To bridge the gap between the two concepts, the Risk Supplement was developed in 2017. This document provides a step-by-step guide on how to implement a vulnerability assessment using the AR5 risk concept.",
    "web page": "https://www.adaptationcommunity.net/download/va/vulnerability-guides-manuals-reports/vuln_source_2017_EN.pdf",
    "Area covered": "",
    "Target user": "Project planner; Policy maker; Development practitioner; Decision-maker"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Natural environment",
    "Theme": "Risk",
    "Type of resource": "Guide",
    "Resource Name": "Conservation Standards",
    "Institution (lead)": "Conservation Measures Partnership",
    "Description": "Open Standards for the Practice of Conservation provides a set of best practices for the implementation of conservation projects. The latest version, version 4.0, released in 2020, addresses climate change considerations in more detail, with new tools developed for adaptation.",
    "web page": "https://conservationstandards.org/about/",
    "Area covered": "",
    "Target user": "Project planner; Development practitioner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Disaster readiness",
    "Theme": "Risk",
    "Type of resource": "Guide",
    "Resource Name": "Technical guidance on comprehensive risk assessment and planning in the context of climate change",
    "Institution (lead)": "United Nations Office for Disaster Risk Reduction",
    "Description": "This document assists disaster risk reduction (DRR) and climate change adaptation decision-makers, practitioners and stakeholders in developing more responsive plans and policies. The document provides a framework on how to apply comprehensive risk assessment and planning.  It acknowledges that risks in the context of climate change are complex and systemic due to non-linear interactions amongst system components and the need for improved risk governance. The guidance can be contextualised to national and local needs.",
    "web page": "https://www.undrr.org/media/79566/download",
    "Area covered": "",
    "Target user": "Policy maker; Decision-maker; Practitioner; Project planner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture; Disaster readiness; Economic; Natural environment; Health; Infrastructure; Water",
    "Theme": "Risk",
    "Type of resource": "Tool",
    "Resource Name": "Climate Risk Screening and Management Tools",
    "Institution (lead)": "USAID",
    "Description": "The website provides climate risk screening and management tools for application during the design phase of strategies, projects and activities. It includes an 'agriculture annex' which gives examples of climate risks, adaptive capacity, opportunities and management options.",
    "web page": "https://www.climatelinks.org/resources/climate-risk-screening-and-management-tools",
    "Area covered": "",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "",
    "Theme": "Risk; Adaptation",
    "Type of resource": "Guide - training manual",
    "Resource Name": "Climate basics, climate information service, climate risk management training guide",
    "Institution (lead)": "CGIAR",
    "Description": "This training manual comprises seven modules covering, but not limited to, information on climate variability, climate change and trends; information on climate information service (CIS), enabling environments, CIS communication methods, and the benefits of CIS; types of CIS practices and tools; and climate risk management.",
    "web page": "https://hdl.handle.net/10568/126096",
    "Area covered": "Ethiopia",
    "Target user": "Decision-maker; Development practitioner; Project planner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Crops; Livestock",
    "Theme": "Risk; Adaptation",
    "Type of resource": "Guide",
    "Resource Name": "The Climate-Smart Agriculture Papers",
    "Institution (lead)": "multiple authors",
    "Description": "This book provides information relating to climate-smart agriculture (CSA) with a focus on Eastern and Southern Africa. It identifies climate risks to smallholder farmer; how to effectively distribute climate-smart varieties; how to prioritise water and soil management options; how to reduce climate risk to value chains; and how to scale up climate risk reduction strategies.",
    "web page": "https://hdl.handle.net/10568/99250",
    "Area covered": "Eastern Africa; Southern Africa",
    "Target user": "Policy maker; Researcher"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Risk; Gender",
    "Type of resource": "Guide - methodology",
    "Resource Name": "Climate change risk assessments in value chain projects",
    "Institution (lead)": "International Fund for Agricultural Development",
    "Description": "This document provides a means for introducing climate risk analysis into the design phase of a value chain project. It follows 5 key steps: 1. identifying the value chain; 2. identifying key climate risks along the chain; 3. choosing effective mitigation measures; 4. targeting the people most vulnerable to risk; and, 5. reaching scale.",
    "web page": "https://www.ifad.org/documents/38714170/40195554/Climate+change+risk+assessments+in+Value+Chain+Projects.pdf/e0fd0f38-42fe-4418-beda-56aff9c8bebf?t=1555415749000",
    "Area covered": "",
    "Target user": "Project planner; Development practitioner"
  },
  {
    "Project phase/component": "3. Intervention and activity design",
    "Target sector": "Agriculture",
    "Theme": "Vulnerability; Adaptation; Gender",
    "Type of resource": "Tool",
    "Resource Name": "Promoting Gender Equitable Opportunities in Agricultural Value Chains (INGIA-VC)",
    "Institution (lead)": "USAID",
    "Description": "This handbook presents the 'Integrating Gender Issues into Agricultural Value Chains' (INGIA-VC) approach. It provides users with an understanding of agricultural value chains from a gender perspective. The handbook helps practitioners become familiar with: how gender issues affect agricultural value chains; a process for analysing gender issues in agricultural value chains; and strategies for addressing gender issues in agricultural value chains.",
    "web page": "https://pdf.usaid.gov/pdf_docs/pnaeb644.pdf",
    "Area covered": "",
    "Target user": "Development practitioner; Project planner"
  },
  {
    "Project phase/component": "4. Monitoring, evaluation and learning",
    "Target sector": "Agriculture",
    "Theme": "Adaptation",
    "Type of resource": "Guide",
    "Resource Name": "Foundations for common approaches to measure global adaptation actions in the agriculture sector: Highlights from an analysis of existing climate adaptation frameworks",
    "Institution (lead)": "multiple authors",
    "Description": "This brief draws upon the learnings from key frameworks for measuring adaptation in the agricultural sector. It aims to determine common measurement approaches for assessing adaptation effectiveness more consistently. It highlights the need to identify synergies with existing processes and to improve information systems for better data collection and reporting.",
    "web page": "https://hdl.handle.net/10568/109718",
    "Area covered": "",
    "Target user": "Decision-maker; Policy maker; Development practitioner"
  },
  {
    "Project phase/component": "4. Monitoring, evaluation and learning",
    "Target sector": "Agriculture",
    "Theme": "Adaptation; Gender",
    "Type of resource": "Guide - journal article",
    "Resource Name": "Enhancing climate services design and implementation through gender-responsive evaluation",
    "Institution (lead)": "multiple authors",
    "Description": "This article provides guidance on gender-responsive evaluation of climate services. It uses case studies from Mali, Rwanda and Southeast Asia to demonstrate how to evaluate climate information needs, access to information, support through group processes, and contribution of climate services to empowerment. It demonstrates the benefits of both quantitative and qualitative evaluation methodologies and the importance of drawing upon evidence in the design of climate service interventions.",
    "web page": "https://cgspace.cgiar.org/handle/10568/126674",
    "Area covered": "Mali; Rwanda; Southeast Asia",
    "Target user": "Researcher; Development practitioner; Project planner; Investors"
  },
  {
    "Project phase/component": "4. Monitoring, evaluation and learning",
    "Target sector": "Disaster readiness",
    "Theme": "Risk",
    "Type of resource": "Guide - methodology",
    "Resource Name": "Resilience rating system: A methodology for building and tracking resilience to climate change",
    "Institution (lead)": "World Bank Group",
    "Description": "The Resilience Rating System provides information to decision-makers, investors, and other stakeholders on the resilience of projects. It gives guidance and criteria to assess the confidence that a project will meet anticipated investment outcomes by assessing whether a project has incorporated climate and disaster risks in its development and means of adaptation. It also rates a project based on its contribution to adaptive development and whether the investments are aligned with increasing climate resilience in the larger scale.",
    "web page": "https://openknowledge.worldbank.org/handle/10986/35039",
    "Area covered": "",
    "Target user": "Decision-maker; Investor; Development practitioner; Project planner"
  },
  {
    "Project phase/component": "4. Monitoring, evaluation and learning",
    "Target sector": "",
    "Theme": "Vulnerability; Gender",
    "Type of resource": "Data",
    "Resource Name": "Gender Inequality Index (GII)",
    "Institution (lead)": "United Nations Development Programme",
    "Description": "The Gender Inequality Index (GII) provides a metric of gender inequality that addresses three key dimensions: reproductive health, empowerment and the labour market. A high GII value indicates high inequality between women and men.",
    "web page": "https://hdr.undp.org/data-center/thematic-composite-indices/gender-inequality-index#/indicies/GII",
    "Area covered": "Global",
    "Target user": "Development practitioner; Project planner; Policy maker; Decision-maker;"
  }
];