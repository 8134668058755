import HandleBars from 'handlebars';



export const QueriesFetcher = async(filename, templateData) => {
  // we can load plain text files like filname.sparql or there is templateData then
  // we load handlebars templates
  const response = await fetch(`sparql/queries/${filename}.sparql${templateData? '.template.handlebars':''}`);

  if(response.ok) {
    let text = await response.text();
    if(templateData)
      text = HandleBars.compile(text)(templateData)
    
    //console.log("///// QUERY /////"); console.log(text);    
    return text;
  }    
  else {
    // capture the error message
    const err = await response.text();            
    throw Error(
      (err.error + ': ' + err.message).replace(/(\r\n|\n|\r)/gm, "")
    );
  }
}