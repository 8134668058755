import { Component, Fragment } from "react";
import * as _ from 'lodash';
import {Context } from '../../context/context'
import { LABELS } from '../../Constants';
import { BsXCircleFill } from "react-icons/bs";
import ListGroup from 'react-bootstrap/ListGroup'

class FilterList extends Component {

  static contextType = Context;

  render() {
    let { filters, updateFilters } = this.context;    

    if(_.isEmpty(filters))
      return <Fragment/>
    else
      return <Fragment>
        <p>Your current selection of the context is characterized by the following attributes:</p>
        {
          _(filters)
            .groupBy('category')
            .toPairs()
            .value()
            .map( (arr, i) => 
              <div className="filter-category" key={i}>
                {arr[1].map( (item, j) => <div className="filter-tag" key={j}>
                    <span>{item.key}</span>
                    <BsXCircleFill onClick={() => updateFilters(item)}/>
                  </div>
                  )}                  
              { /*
                <ListGroup horizontal>
                  <ListGroup.Item variant="primary" size="sm">
                    {LABELS[arr[0]]}:
                  </ListGroup.Item>
                  {arr[1].map( (item, j) => <ListGroup.Item className="filter-tag" key={j}>
                    <span>{item.key}</span>
                    <BsXCircleFill onClick={() => updateFilters(item)}/>
                  </ListGroup.Item>
                  )}                  
                </ListGroup>
            */}
              </div>
            )
        }
        </Fragment>
  }
}
export default FilterList


/*
 <ListGroup horizontal>
      <ListGroup.Item>This</ListGroup.Item>
      <ListGroup.Item>ListGroup</ListGroup.Item>
      <ListGroup.Item>renders</ListGroup.Item>
      <ListGroup.Item>horizontally!</ListGroup.Item>
    </ListGroup>

*/