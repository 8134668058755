import { Component } from "react";
import * as _ from 'lodash';

import {Context } from '../../context/context';
import {
  DIMENSIONS, 
  FARMING_SYSTEM_DIM, 
  VIEW_USER_GUIDE
} from '../../Constants';
import FacetBars from "./FacetBars";
import { Button, Container, Row, Alert, Spinner, Col } from "react-bootstrap";
import FilterList from "./FilterList";

class ImpactChainFacetBrowser extends Component {

  static contextType = Context;

  render() {
    // object containing per each dimension, its values, occurrences
    // and whether they are selected or not
    let impactChainsByDimension = {},
      { dimensions, filters, fetchFactors} = this.context;

    // reset any previous selected IC in the App.js state
    let {
      resetICModelName
    } = this.props;

    if(!_.isEmpty(dimensions)) {
      DIMENSIONS.forEach( dimension => {
        impactChainsByDimension[dimension] = dimensions[dimension].group().all();
        // add state of selected to the dimension items
        impactChainsByDimension[dimension].forEach(item => {        
          item.selected = _.find(filters, ['key', item.key]) !== undefined;
        })
      });
    }
      
    if(_.isEmpty(impactChainsByDimension))
      return <div className="facet-browser-init-alert">
        <Alert key="primary" variant="primary">
        <Spinner animation="border" size="sm"/>{' '}Initializing, please wait...
          </Alert>
      </div>
    else
      return <Container className="facet-browser">
        <Row>
          <Col>
            <p className="info">
              Select first the countries you are working in and then the farming system.
              <br/>
              Select only one farming system.
              <a href="pdf/CRISP_User_Guide.pdf#page=12" target="_blank">
                <span className="userguide-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                  </svg>
                </span>
              </a>
            </p>
            <div className="impact-chain-facet-browser">
            {
              DIMENSIONS.map((dimension, key) => <FacetBars            
                key={key}
                category={dimension}
                data={impactChainsByDimension[dimension]}>
              </FacetBars>)
            }
            </div>
          </Col>
          <Col>
            <p className="info">
              Zoom into the map to view and identify the farming system relevant to your project:
              <a href="pdf/CRISP_User_Guide.pdf#page=13" target="_blank">
                <span className="userguide-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                  </svg>
                </span>
              </a>
            </p>
          <iframe width="100%" height="375" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="Farming Systems Dixon et al." src="https://scientificnet.maps.arcgis.com/apps/Embed/index.html?webmap=a144b436bbab46ed8cd5851411e1534e&center=16.358889494649784,15.457161458465702&level=2&zoom=true&previewImage=false&scale=true&legendlayers=true&disable_scroll=true&theme=light"/>        
          </Col>          
        </Row>  
        <Row className="filter-list-row">
            <Col>
              <FilterList/>
            </Col>
            <Col>
              <br/>
              <Row>
              <Col>
                  <Button
                    disabled={_.isEmpty(filters) || _.isUndefined(_.find(filters, ['category', FARMING_SYSTEM_DIM]))}
                    className="btn-view-impact-chains"
                    variant={_.isEmpty(filters)? "outline-secondary":"success"}
                    onClick={() => { resetICModelName(); fetchFactors(); }}>
                      View Impact Chain
                  </Button>
                </Col>
              </Row>              
            </Col>
        </Row>  
        <Row className="btn-view-impact-chains-row">
          
        </Row>    
      </Container>
  }
}
export default ImpactChainFacetBrowser