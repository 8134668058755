import * as d3 from 'd3';
import forceBoundary from 'd3-force-boundary';
import * as _ from 'lodash';
import { FACTOR_SPECIALIZATIONS, FACTOR_SPECIALIZATION_EXPOSURE, FACTOR_SPECIALIZATION_RISK } from '../Constants';
//import {quadtree} from 'd3-quadtree';

export const wrap = function(text, wrapWidth, yAxisAdjustment = 0) {
  text.each(function() {
    var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        //lineNumber = 0,
        lineHeight = 1, // ems
        y = text.attr("y"),
        dy = 0, /*parseFloat(text.attr("dy")) - yAxisAdjustment,*/
        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", `${dy}em`);
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > wrapWidth) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", lineHeight + dy + "em").text(word);
      }
    }
  });
  return 0;
};

const NODE_RADIUS = 35;

function forceCollide() {
  const alpha = 0.4; // fixed for greater rigidity!
  const padding1 = 10; // separation between same-color nodes
  const padding2 = 100; // separation between different-color nodes
  let nodes;
  let maxRadius;

  function force() {
    const quadtree = d3.quadtree(nodes, d => d.x, d => d.y);
    for (const d of nodes) {
      const r = NODE_RADIUS + maxRadius;
      const nx1 = d.x - r, ny1 = d.y - r;
      const nx2 = d.x + r, ny2 = d.y + r;
      quadtree.visit((q, x1, y1, x2, y2) => {
        if (!q.length) do {
          if (q.data !== d) {
            const r = NODE_RADIUS + NODE_RADIUS + (d.data.factorTypeName === q.data.data.factorTypeName ? padding1 : padding2);
            let x = d.x - q.data.x, y = d.y - q.data.y, l = Math.hypot(x, y);
            if (l < r) {
              l = (l - r) / l * alpha;
              d.x -= x *= l;
              d.y -= y *= l;
              q.data.x += x;
              q.data.y += y;
            }
          }
        } while (q = q.next);
        return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
      });
    }
  }

  force.initialize = _ => maxRadius = d3.max(nodes = _, d => NODE_RADIUS) + Math.max(padding1, padding2);

  return force;
}

function centroid(nodes) {
  let x = 0;
  let y = 0;
  let z = 0;
  for (const d of nodes) {
    let k = NODE_RADIUS ** 2;
    x += d.x * k;
    y += d.y * k;
    z += k;
  }
  return {x: x / z, y: y / z};
}

function forceCluster() {
  const strength = 0.3;
  let nodes;

  function force(alpha) {
    const centroids = d3.rollup(nodes, centroid, d => d.data.factorTypeName);    
    const l = alpha * strength;
    for (const d of nodes) {
      const {x: cx, y: cy} = centroids.get(d.data.factorTypeName);      
      d.vx -= (d.x - cx) * l;
      d.vy -= (d.y - cy) * l;
    }
  }
  force.initialize = _ => nodes = _;
  return force;
}

export const getForceSimulation = (data, width, height, clusteredNetworkLayout) => {
  // simulation that adds boundary constraints so isoled groups don´t fly away
  let padding = 50;

    // Exposures and Risks: set fixed positions for them. Place them in a grid,
    // starting atthe top-left corner (0,0 is the center of the canvas)
    let topLeftPosition = {w: -width/2 + 25, h:-height/2 + 15},
      exposures = [],
      risks = [],
      nodeSize ={w: 110, h:50};

    data.nodes.forEach(node => {
      if(node.type === FACTOR_SPECIALIZATION_EXPOSURE) {
        node.fx = topLeftPosition.w + (nodeSize.w * exposures.length);
        node.fy = topLeftPosition.h + 20;
        exposures.push(node)
      }
       
      if(node.type === FACTOR_SPECIALIZATION_RISK) {
        node.fx = topLeftPosition.w + (nodeSize.w * risks.length);
        node.fy = topLeftPosition.h + 20 + nodeSize.h;
        risks.push(node)
      }              
    });

  if(clusteredNetworkLayout) 
    return d3.forceSimulation(data.nodes)
      .force('link', d3.forceLink(data.links).id(function(d) { return d.label; }).distance(35).strength(1))
      .force('charge', d3.forceManyBody().strength(50))
      //.force('collide', d3.forceCollide(56))
      .force("boundary", forceBoundary((-width/2)+padding, (-height/2)+(padding*2), (width/2)-padding, (height/2)-padding))    
      // https://observablehq.com/@d3/clustered-bubbles?collection=@d3/d3-force
      .force('cluster', forceCluster())
      .force('collide', forceCollide())
      .force("y", d3.forceY().y(function(d) {
        // moving it up by height*1.5 is totally arbritary...
        return _.random(0, height) - height*1.5;
      }))
      .force("x", d3.forceX().x(function(d) {
        // this helps a bit the clustering algorithm at having factor types within initial areas
        let slotWidth = width / FACTOR_SPECIALIZATIONS.length;
        let slotIndex = _.indexOf(FACTOR_SPECIALIZATIONS, d.data.factorTypeName);
        return _.random(slotWidth*slotIndex,(slotWidth*slotIndex)+slotWidth) - width/2;
      }));
  else
    return d3.forceSimulation(data.nodes)  
      .force('link', d3.forceLink(data.links).id(function(d) { return d.label; }).distance(35))
      .force('charge', d3.forceManyBody().strength(-200))
      .force('collide', d3.forceCollide(56))
      .force("boundary", forceBoundary((-width/2)+padding, (-height/2)+padding, (width/2)-padding, (height/2)-padding));

  /*
  // simulation below resolves node overlapping by a bboxCollide force,
  // but then links become way too much long 
  
  let nodeWidth = 90, 
      nodeHeight = 30;

  return d3.forceSimulation(data.nodes)
    .force('link', d3.forceLink(data.links).id(function(d) { return d.label; }))
    .force('charge', d3.forceManyBody())
    .force("boundary", forceBoundary((-width/2)+10, (-height/2)+10, (width/2)-10, (height/2)-10))
    .force(
      'collide', 
      bboxCollide((d,i) => [[-nodeWidth / 2, -nodeHeight / 2],[nodeWidth / 2, nodeHeight / 2]])
        .strength(1)
        .iterations(2)
    );
    */
}

export const addMarkerDefs = (svg) => {
  let markerSize = 10;
  const defs = svg.append('defs')
    defs.append('marker')      
        .attr('id', 'arrowhead-end')
        .attr('viewBox', '-0 -5 10 10')
        .attr('markerUnits', 'userSpaceOnUse')
        .attr('refX', 20)
        .attr('refY', 0)
        .attr('orient', 'auto')
        .attr('markerWidth', markerSize)
        .attr('markerHeight', markerSize)
        .attr('xoverflow', 'visible')
      .append('svg:path')
      .attr('stroke', 'lightgrey')
      .attr('fill', 'lightgrey')
      .attr('stroke-width', '1px')
        .attr('d', 'M 0,-5 L 10 ,0 L 0,5');

    defs.append('marker')        
        .attr('id', 'arrowhead-start')
        .attr('viewBox', '-0 -5 10 10')
        .attr('markerUnits', 'userSpaceOnUse')
        .attr('refX', 20)
        .attr('refY', 0)
        .attr('orient', 'auto-start-reverse')
        .attr('markerWidth', markerSize)
        .attr('markerHeight', markerSize)
        .attr('xoverflow', 'visible')
      .append('svg:path')
      .attr('stroke', 'lightgrey')
      .attr('fill', 'lightgrey')
      .attr('stroke-width', '1px')
        .attr('d', 'M 0,-5 L 10 ,0 L 0,5');
}

export const fisheye0 = {
  circular: () => {
    var radius = 200,
        distortion = 2,
        k0,
        k1,
        focus = [0, 0];

    function fisheye(d) {
      var dx = d.x - focus[0],
          dy = d.y - focus[1],
          dd = Math.sqrt(dx * dx + dy * dy);
      if (!dd || dd >= radius) return {x: d.x, y: d.y, z: dd >= radius ? 1 : 10};
      var k = k0 * (1 - Math.exp(-dd * k1)) / dd * .75 + .25;
      return {x: focus[0] + dx * k, y: focus[1] + dy * k, z: Math.min(k, 10)};
    }

    function rescale() {
      k0 = Math.exp(distortion);
      k0 = k0 / (k0 - 1) * radius;
      k1 = distortion / radius;
      return fisheye;
    }

    fisheye.radius = function(_) {
      if (!arguments.length) return radius;
      radius = +_;
      return rescale();
    };

    fisheye.distortion = function(_) {
      if (!arguments.length) return distortion;
      distortion = +_;
      return rescale();
    };

    fisheye.focus = function(_) {
      if (!arguments.length) return focus;
      focus = _;
      return fisheye;
    };

    return rescale();
  }
};

/*
function bboxCollide (bbox) {

  function x (d) {
    return d.x + d.vx;
  }

  function y (d) {
    return d.y + d.vy;
  }

  function constant (x) {
    return function () {
      return x;
    };
  }

  var nodes,
      boundingBoxes,
      strength = 1,
      iterations = 1;

      if (typeof bbox !== "function") {
        bbox = constant(bbox === null ? [[0,0][1,1]] : bbox)
      }

      function force () {
        var i,
            tree,
            node,
            xi,
            yi,
            bbi,
            nx1,
            ny1,
            nx2,
            ny2

            var cornerNodes = []
            nodes.forEach(function (d, i) {
              cornerNodes.push({node: d, vx: d.vx, vy: d.vy, x: d.x + (boundingBoxes[i][1][0] + boundingBoxes[i][0][0]) / 2, y: d.y + (boundingBoxes[i][0][1] + boundingBoxes[i][1][1]) / 2})
              cornerNodes.push({node: d, vx: d.vx, vy: d.vy, x: d.x + boundingBoxes[i][0][0], y: d.y + boundingBoxes[i][0][1]})
              cornerNodes.push({node: d, vx: d.vx, vy: d.vy, x: d.x + boundingBoxes[i][0][0], y: d.y + boundingBoxes[i][1][1]})
              cornerNodes.push({node: d, vx: d.vx, vy: d.vy, x: d.x + boundingBoxes[i][1][0], y: d.y + boundingBoxes[i][0][1]})
              cornerNodes.push({node: d, vx: d.vx, vy: d.vy, x: d.x + boundingBoxes[i][1][0], y: d.y + boundingBoxes[i][1][1]})
            })
            var cn = cornerNodes.length

        for (var k = 0; k < iterations; ++k) {
          tree = quadtree(cornerNodes, x, y).visitAfter(prepareCorners);

          for (i = 0; i < cn; ++i) {
            var nodeI = ~~(i / 5);
            node = nodes[nodeI]
            bbi = boundingBoxes[nodeI]
            xi = node.x + node.vx
            yi = node.y + node.vy
            nx1 = xi + bbi[0][0]
            ny1 = yi + bbi[0][1]
            nx2 = xi + bbi[1][0]
            ny2 = yi + bbi[1][1]
            tree.visit(apply);
          }
        }

        function apply (quad, x0, y0, x1, y1) {
            var data = quad.data
            if (data) {
              var bWidth = bbLength(bbi, 0),
              bHeight = bbLength(bbi, 1);

              if (data.node.index !== nodeI) {
                var dataNode = data.node
                var bbj = boundingBoxes[dataNode.index],
                  dnx1 = dataNode.x + dataNode.vx + bbj[0][0],
                  dny1 = dataNode.y + dataNode.vy + bbj[0][1],
                  dnx2 = dataNode.x + dataNode.vx + bbj[1][0],
                  dny2 = dataNode.y + dataNode.vy + bbj[1][1],
                  dWidth = bbLength(bbj, 0),
                  dHeight = bbLength(bbj, 1)

                if (nx1 <= dnx2 && dnx1 <= nx2 && ny1 <= dny2 && dny1 <= ny2) {

                  var xSize = [Math.min.apply(null, [dnx1, dnx2, nx1, nx2]), Math.max.apply(null, [dnx1, dnx2, nx1, nx2])]
                  var ySize = [Math.min.apply(null, [dny1, dny2, ny1, ny2]), Math.max.apply(null, [dny1, dny2, ny1, ny2])]

                  var xOverlap = bWidth + dWidth - (xSize[1] - xSize[0])
                  var yOverlap = bHeight + dHeight - (ySize[1] - ySize[0])

                  var xBPush = xOverlap * strength * (yOverlap / bHeight)
                  var yBPush = yOverlap * strength * (xOverlap / bWidth)

                  var xDPush = xOverlap * strength * (yOverlap / dHeight)
                  var yDPush = yOverlap * strength * (xOverlap / dWidth)

                  if ((nx1 + nx2) / 2 < (dnx1 + dnx2) / 2) {
                    node.vx -= xBPush
                    dataNode.vx += xDPush
                  }
                  else {
                    node.vx += xBPush
                    dataNode.vx -= xDPush
                  }
                  if ((ny1 + ny2) / 2 < (dny1 + dny2) / 2) {
                    node.vy -= yBPush
                    dataNode.vy += yDPush
                  }
                  else {
                    node.vy += yBPush
                    dataNode.vy -= yDPush
                  }
                }

              }
              return;
            }

            return x0 > nx2 || x1 < nx1 || y0 > ny2 || y1 < ny1;
        }

      }

      function prepareCorners (quad) {

        if (quad.data) {
          return quad.bb = boundingBoxes[quad.data.node.index]
        }
          quad.bb = [[0,0],[0,0]]
          for (var i = 0; i < 4; ++i) {
            if (quad[i] && quad[i].bb[0][0] < quad.bb[0][0]) {
              quad.bb[0][0] = quad[i].bb[0][0]
            }
            if (quad[i] && quad[i].bb[0][1] < quad.bb[0][1]) {
              quad.bb[0][1] = quad[i].bb[0][1]
            }
            if (quad[i] && quad[i].bb[1][0] > quad.bb[1][0]) {
              quad.bb[1][0] = quad[i].bb[1][0]
            }
            if (quad[i] && quad[i].bb[1][1] > quad.bb[1][1]) {
              quad.bb[1][1] = quad[i].bb[1][1]
            }
        }
      }

      function bbLength (bbox, heightWidth) {
        return bbox[1][heightWidth] - bbox[0][heightWidth]
      }

      force.initialize = function (_) {
        var i, n = (nodes = _).length; boundingBoxes = new Array(n);
        for (i = 0; i < n; ++i) boundingBoxes[i] = bbox(nodes[i], i, nodes);
      };

      force.iterations = function (_) {
        return arguments.length ? (iterations = +_, force) : iterations;
      };

      force.strength = function (_) {
        return arguments.length ? (strength = +_, force) : strength;
      };

      force.bbox = function (_) {
        return arguments.length ? (bbox = typeof _ === "function" ? _ : constant(+_), force) : bbox;
      };

      return force;
}
*/