
import { Component } from "react";

class Videos extends Component {

  render() {    
    return <div className="wrapper-tools">
      <h3>Use case videos</h3>
      <p>
        Find below some videos showcasing various practical use cases of the CRISP tool.<br/>These videos are aimed to explore the tool's capabilities and potential in action.
      </p>   
      <h5>CRISP tool – short demo</h5>     
      <p className="video-description">
        This short demo video introduces the CRISP tool to conduct a climate risk screening to articulate science-based adaptation hypotheses based on the development of impact chains.
      </p>
      <video controls width="75%">
        <source src="./video/CRISP_short_demo_2mins47.mp4" type="video/mp4" />  
      </video>

      <h5>CRISP tool – demo – starting from the hazard</h5> 
      <p className="video-description">
        In this video we showcase how to use the CRISP tool to conduct a climate risk screening to articulate science-based adaptation hypotheses based on the development of impact chains. In this case study we are interested in answering the question: “Which are the main climate-related hazards, impacts, vulnerabilities and potential adaptation options relevant to the farming system in which my agricultural development project will be implemented?"
      </p>    
      <video controls width="75%">
        <source src="./video/CRISP_demo_starting_from_the_hazard_3m43.mp4" type="video/mp4" />  
      </video>

      <h5>CRISP tool – demo – starting from the adaptation</h5>
      <p className="video-description">
        In this video we showcase how to use the CRISP tool to conduct a climate risk screening to articulate science-based adaptation hypotheses based on the development of impact chains. In this case study we are interested in answering the question: "Are the adaptation options promoted by my project responding to all the main climatic hazards and impacts affecting the specific farming system where they are implemented? Are there additional suitable adaptation options my project is not yet implementing and that would be worth considering?"
      </p>
      <video controls width="75%">
        <source src="./video/CRISP_demo_starting_from_adaptation_4m12.mp4" type="video/mp4" />  
      </video>
    </div>
  }
}
export default Videos;