import { Component } from "react";

class Background extends Component {

  render() {
    return <div className="wrapper-background">
      <h3>The basics</h3>
      <p className="short">
        <strong>What you need to know before getting started</strong><br/><br/>
        This section briefly presents the core concepts upon which the CRISP tool is based. Please take a few minutes to familiarise yourself with these concepts. This will support your experience when using the tool.
      </p>
      <h3>
        <svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">1</text>
        </svg>
        Understanding climate risk
      </h3>
      <p className="short">
        Risks are highly context specific. In the context of climate change, “…they <strong>result from dynamic interactions of climate-related hazards</strong> with the levels of <strong>exposure</strong> and <strong>vulnerability</strong> of the affected human or ecological system to those hazards.”(Figure 1)(<a href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_AnnexVII.pdf" target="_blank" rel="noreferrer">IPCC A6 Glossary</a>).
      </p>
      <img src="./images/background/crisp-background1.png" alt="CRISP"/>
      <p className="img-footer">
        Figure 1: the IPCC AR6 risk concept (adapted from <a href="https://www.ipcc.ch/report/ar6/wg2/figures/summary-for-policymakers/figure-spm-1" target="_blank" rel="noreferrer">IPCC, 2022</a>)
      </p>
      <h3>{''}</h3>
      <div className="tool-points">
        <div>
          <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_995)">
              <path d="M20.9453 40.0001C31.991 40.0001 40.9453 31.0458 40.9453 20.0001C40.9453 8.95443 31.991 0.00012207 20.9453 0.00012207C9.89962 0.00012207 0.945312 8.95443 0.945312 20.0001C0.945312 31.0458 9.89962 40.0001 20.9453 40.0001Z" fill="#2A66FF"/>
              <path d="M33.7651 26.6401L22.5451 6.28008C21.8451 5.02008 20.0451 5.02008 19.3451 6.28008L8.12512 26.6401C7.46512 27.8601 8.32512 29.3401 9.72512 29.3401H32.1651C33.5451 29.3401 34.4251 27.8601 33.7651 26.6401ZM19.3051 10.9801C19.3051 10.1601 19.9851 9.48008 20.8051 9.48008C21.6251 9.48008 22.3051 10.1601 22.3051 10.9801V20.0401C22.3051 20.8601 21.6251 21.5401 20.8051 21.5401C19.9851 21.5401 19.3051 20.8601 19.3051 20.0401V10.9801ZM20.8051 27.1001C19.7251 27.1001 18.8451 26.2201 18.8451 25.1401C18.8451 24.0601 19.7251 23.1801 20.8051 23.1801C21.8851 23.1801 22.7651 24.0601 22.7651 25.1401C22.7651 26.2201 21.8851 27.1001 20.8051 27.1001Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_13_995">
              <rect width="40" height="40" fill="white" transform="translate(0.944824)"/>
              </clipPath>
            </defs>
          </svg>
          <br/>
          <strong>Climate-related hazard</strong>: any type of climate extremes (heatwaves, droughts, extreme precipitation events, storms), the impact of climate change on such extremes (increasing intensity and frequency of extreme events) as well as slow-onset processes (increasing temperatures, increasing aridity, glacier melt or sea-level rise) that are triggering adverse consequences for human or ecological systems (<a href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_AnnexVII.pdf" target="_blank" rel="noreferrer">IPCC, 2021</a>).          
        </div>
        <div>
          <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_13_1000)">
              <path d="M20.9451 40.0001C31.9908 40.0001 40.9451 31.0458 40.9451 20.0001C40.9451 8.95443 31.9908 0.00012207 20.9451 0.00012207C9.89937 0.00012207 0.945068 8.95443 0.945068 20.0001C0.945068 31.0458 9.89937 40.0001 20.9451 40.0001Z" fill="#cfcf15"/>
              <path d="M20.8244 12.2401C17.9644 15.1001 16.6844 18.9601 16.9844 22.7001C17.0444 23.5201 17.7044 24.1801 18.5244 24.2401C22.2644 24.5401 26.1244 23.2601 28.9844 20.4001C31.8444 17.5401 33.1244 13.6801 32.8244 9.94014C32.7644 9.12014 32.1044 8.46014 31.2844 8.40014C27.5444 8.10014 23.6844 9.38014 20.8244 12.2401Z" fill="white"/>
              <path d="M14.5249 34.9201C13.7049 34.9201 13.0249 34.2401 13.0249 33.4201V31.4201C13.0249 27.9601 14.3849 24.6801 16.8249 22.2401C17.4049 21.6601 18.3649 21.6601 18.9449 22.2401C19.5249 22.8201 19.5249 23.7801 18.9449 24.3601C17.0649 26.2401 16.0249 28.7601 16.0249 31.4201V33.4201C16.0249 34.2401 15.3449 34.9201 14.5249 34.9201Z" fill="white"/>
              <path d="M15.4845 16.7602C17.1045 18.3802 17.8245 20.5402 17.6445 22.6602C17.6045 23.1202 17.2445 23.5002 16.7845 23.5202C14.6845 23.7002 12.5045 22.9802 10.8845 21.3602C9.26451 19.7402 8.54451 17.5802 8.72451 15.4602C8.76451 15.0002 9.12451 14.6202 9.58451 14.6002C11.6845 14.4202 13.8645 15.1402 15.4845 16.7602Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_13_1000">
              <rect width="40" height="40" fill="white" transform="translate(0.944824)"/>
              </clipPath>
            </defs>
          </svg>
          <br/>
          <strong>Exposure</strong>: who or what is exposed to the risk. This could be “people, livelihoods, species or ecosystems, environmental functions, services, and resources, infrastructure, or economic, social, or cultural assets” (<a href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_AnnexVII.pdf" target="_blank" rel="noreferrer">IPCC, 2021</a>). Examples include the number of people within an exposed system, the area of cropland within a region. The higher the degree of exposure that could be adversely affected, the higher the climate risk.
        </div>
        <div>
          <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_528_1560)">
              <path d="M20.0003 40.2125C31.046 40.2125 40.0003 31.2582 40.0003 20.2125C40.0003 9.16683 31.046 0.212524 20.0003 0.212524C8.95461 0.212524 0.000305176 9.16683 0.000305176 20.2125C0.000305176 31.2582 8.95461 40.2125 20.0003 40.2125Z" fill="#2fe09c"/>
              <path d="M11.0607 22.3325H17.5007L15.6207 33.4525C15.5607 33.8325 15.6607 34.2125 15.9007 34.4925C16.1407 34.7725 16.5207 34.9325 16.8807 34.9325C17.2607 34.9325 17.6207 34.7525 17.8407 34.4525L28.5807 20.7325C28.7807 20.4925 28.8807 20.1725 28.8407 19.8525C28.8207 19.5325 28.6807 19.2325 28.4407 19.0325C28.2007 18.8125 27.9007 18.6925 27.5807 18.6925H21.1407L23.0207 7.57253C23.0807 7.19253 22.9807 6.81253 22.7407 6.53253C22.5007 6.25253 22.1207 6.09253 21.7607 6.09253C21.3807 6.09253 21.0207 6.27253 20.8007 6.57253L10.0607 20.2925C9.86068 20.5525 9.76068 20.8525 9.80068 21.1725C9.82068 21.4925 9.96068 21.7925 10.2007 21.9925C10.4407 22.2125 10.7407 22.3325 11.0607 22.3325Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_528_1560">
              <rect width="40" height="40" fill="white" transform="translate(0 0.212402)"/>
              </clipPath>
            </defs>
          </svg>
          <br/>
            <strong>Vulnerability</strong> is “the predisposition to be adversely affected” by climate impacts. “Vulnerability encompasses a variety of concepts and elements including sensitivity or susceptibility to harm and lack of capacity to cope and adapt” (<a href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_AnnexVII.pdf" target="_blank" rel="noreferrer">IPCC, 2021</a>). Vulnerability includes all relevant environmental, physical, technical, social, cultural, economic, institutional, or policy-related factors that contribute to a susceptibility and/or a lack of capacity to prepare, prevent, respond, cope or adapt.
        </div>
        <div>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.95431 0 0 8.9543 0 20C0 31.0457 8.95431 40 20 40Z" fill="white"/>
            <path d="M28.7213 22.4553H11.2787C9.95711 22.4553 8.88574 23.5267 8.88574 24.8483C8.88574 26.1699 9.95711 27.2413 11.2787 27.2413H28.7213C30.0429 27.2413 31.1143 26.1699 31.1143 24.8483C31.1143 23.5267 30.0429 22.4553 28.7213 22.4553Z" fill="#e0472f"/>
            <path d="M27.0656 13.5809C26.6063 10.3203 23.6349 7.79126 20.0001 7.79126C16.3653 7.79126 13.3939 10.3203 12.9346 13.5809L10.8535 23.6348H29.1523L27.0713 13.5809H27.0656Z" fill="#e0472f"/>
            <path d="M16.2178 28.761C16.2178 28.761 16.2178 28.7723 16.2178 28.778C16.2178 30.8704 17.9133 32.5659 20.0057 32.5659C22.0981 32.5659 23.7936 30.8704 23.7936 28.778C23.7936 28.7723 23.7936 28.7667 23.7936 28.761H16.2291H16.2178Z" fill="#e0472f"/>
            <path d="M20.4878 6.03906H19.5125C18.8611 6.03906 18.333 6.56714 18.333 7.21854V8.44338C18.333 9.09479 18.8611 9.62285 19.5125 9.62285H20.4878C21.1392 9.62285 21.6673 9.09479 21.6673 8.44338V7.21854C21.6673 6.56714 21.1392 6.03906 20.4878 6.03906Z" fill="#e0472f"/>
          </svg>
          <br/>
          <strong>Risk</strong> is the “potential for adverse consequences for human or ecological systems.” “Risks can arise from potential impacts of climate change as well as human responses to climate change”. “Risk in the context of Climate Change results from dynamic interactions between climate-related hazards with the exposure and vulnerability of the affected human or ecological systems to the hazards.” (<a href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_AnnexVII.pdf" target="_blank" rel="noreferrer">IPCC, 2021</a>).
        </div>
      </div>
      <h3>
        <svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">2</text>
        </svg>
        The concept of impact chains
      </h3>
      <p className="short">
        In the CRISP tool you will find the climate risk information presented according to the concept of <strong>climate risk impact chains</strong> (Zebisch et al., 2022, 2017). Adapted from the IPCC risk concept, an impact chain visualises in a schematic way, the relationships between climate related hazards, exposure, impacts and vulnerability factors. The chain concept illustrates how these factors all influence each other and together constitute the climate risk.<br/>Adaptation options are added to show entry points for climate risk management aiming at decreasing vulnerabilities (Figure 2).<br/>A climate risk impact chain is always context-specific and thus helps to illustrate and understand the specific factors of climate risk in a given location.
      </p>
      <img src="./images/background/crisp-background2.png" alt="CRISP"/>
      <p className="img-footer">
        Figure 2: Translation of the IPCC AR6 risk propeller into an impact chain visualisation adapted from the <a href="https://www.adaptationcommunity.net/download/va/vulnerability-guides-manuals-reports/vuln_source_2017_EN.pdf" target="_blank" rel="noreferrer">Vulnerability Sourcebook Risk Supplement</a>.        
      </p>
      <p className="short">
        Impacts and Adaptation options are two elements that were added in the Impact Chain visualisation compared with the IPCC risk propeller.
      </p>
      <div className="tool-points-centered">
        <div>
          <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_528_1656)">
              <path d="M20 40.2124C31.0457 40.2124 40 31.2581 40 20.2124C40 9.16671 31.0457 0.212402 20 0.212402C8.9543 0.212402 0 9.16671 0 20.2124C0 31.2581 8. 9543 40.2124 20 40.2124Z" fill="#404649"/>
              <path d="M25.32 26.1525C25.06 26.6525 24.54 26.9525 24 26.9525C24 26.9525 23.98 26.9525 23.96 26.9525C23.38 26.9525 22.88 26.5925 22.64 26.0725L20 20.2325L18.88 22.6125C18.52 23.3525 17.64 23.6925 16.88 23.3325C16.12 22.9725 15.8 22.0925 16.16 21.3325L18.66 16.0125C18.9 15.4925 19.44 15.1525 20.02 15.1525C20.6 15.1525 21.14 15.4925 21.38 16.0325L24.1 22.0525L29.32 12.0925V10.9525C29.32 9.2125 27.92 7.8125 26.18 7.8125H13.88C12.14 7.8125 10.74 9.2125 10.74 10.9525V29.0725C10.74 30.8125 12.14 32.2125 13.88 32.2125H26.18C27.92 32.2125 29.32 30.8125 29.32 29.0725V18.5725L25.32 26.1925V26.1525Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_528_1656">
              <rect width="40" height="40" fill="white" transform="translate(0 0.212402)"/>
              </clipPath>
            </defs>
          </svg>
          <strong>Impacts</strong> describe the “consequences or outcomes of risks on natural and human systems…” and refer to “..effects on lives, livelihoods, health and wellbeing, ecosystems and species, economic, social and cultural assets, services and infrastructure.” <a href="https://www.ipcc.ch/report/ar6/wg1/downloads/report/IPCC_AR6_WGI_AnnexVII.pdf" target="_blank" rel="noreferrer">IPCC, 2021</a>
        </div>
        <div>
          <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_528_1661)">
              <path d="M20 40.2124C31.0457 40.2124 40 31.2581 40 20.2124C40 9.16671 31.0457 0.212402 20 0.212402C8.9543 0.212402 0 9.16671 0 20.2124C0 31.2581 8.9543 40.2124 20 40.2124Z" fill="#8c4829"/>
              <path d="M31.56 12.0324C31.52 11.9324 31.44 11.8524 31.32 11.8324C31.22 11.8124 31.1 11.8324 31.02 11.9124L27.8 15.1324L25.2 14.3324L24.4 11.7324L27.62 8.51239C27.62 8.51239 27.72 8.33239 27.7 8.21239C27.68 8.11239 27.6 8.01239 27.5 7.97239C25.84 7.35239 24 7.37239 22.36 8.05239C20.72 8.73239 19.4 10.0124 18.68 11.6324C17.96 13.2524 17.86 15.0924 18.44 16.7724L9.11997 26.3724C8.53997 26.9524 8.21997 27.7124 8.21997 28.5324C8.21997 29.3524 8.53997 30.1124 9.11997 30.6924C9.69997 31.2724 10.46 31.5924 11.28 31.5924C12.1 31.5924 12.86 31.2724 13.44 30.6924L22.74 21.0924C24.42 21.6724 26.26 21.5924 27.88 20.8724C29.5 20.1524 30.78 18.8324 31.46 17.1724C32.14 15.5324 32.16 13.6924 31.54 12.0324H31.56ZM11.42 30.2124C10.42 30.2124 9.61997 29.4124 9.61997 28.4124C9.61997 27.4124 10.42 26.6124 11.42 26.6124C12.42 26.6124 13.22 27.4124 13.22 28.4124C13.22 29.4124 12.42 30.2124 11.42 30.2124Z" fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0_528_1661">
              <rect width="40" height="40" fill="white" transform="translate(0 0.212402)"/>
              </clipPath>
            </defs>
          </svg>
          <strong>Adaptation options</strong>are mitigating one or multiple vulnerability factors and thus reduce the overall climate-related risk, by lowering the degree of exposure to climate-related hazards or by mitigating cascading impacts.
        </div>
      </div>
      <h3>
        <svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">3</text>
        </svg>
        Agricultural / farming system
      </h3>
      <p className="short">
        The CRISP tool uses the <a href="https://www.fao.org/family-farming/detail/en/c/273641/" target="_blank" rel="noreferrer">Dixon et al. 2001</a> (updated for Africa in 2019, (<a href="https://www.taylorfrancis.com/books/9781317332275" target="_blank" rel="noreferrer">Dixon et al., 2019</a>)) farming system classification to contextualise the climate risk impact chains to a manageable level of analysis. In an ideal situation, a climate risk impact chain would be constructed for a specific location, but since this is impractical to do within a web-based tool, the farming system classifications provide a feasible degree of analysis for this type of tool. The Dixon classification of farming systems is based on similar agroecological, physical, economic and cultural environments within which farmers and their families live (Dixon et al., 2001). The classification distinguishes 72 farming systems in six macro regions. The macro regions are: Sub-Saharan Africa, Middle East and Northern Africa, Eastern Europe and Central Asia, South Asia, East Asia and Pacific and Latin America and Caribbean. In the CRISP tool you will find climate risk impact chains for 23 farming systems in five macro regions as listed in Table 1. The commodities outlined in the table below are the key ones in the farming systems. However, the list is not exhaustive as each of the farming systems has many more value chains.<br/>What we want to say clearly is that the data base that supports CRISP does not have information for every location on Earth but uses as scale/unit of analysis, information relevant to the level of farming systems.
      </p>
      <img src="./images/background/crisp-background3.png" alt="CRISP"/>
      <p className="img-footer">
        Table 1: Overview of the 23 farming systems covered by the climate risk impact chains in the CRISP tool.
      </p>
      <p className="short">
        <strong>Explore the interactive map below to find the farming system(s) of your interest.</strong>
      </p>
      <iframe width="100%" height="500" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="Farming Systems Dixon et al." src="https://scientificnet.maps.arcgis.com/apps/Embed/index.html?webmap=a144b436bbab46ed8cd5851411e1534e&extent=-23.8248,-16.8829,68.4603,29.0038&zoom=true&previewImage=false&scale=true&legendlayers=true&disable_scroll=true&theme=light">        
      </iframe>
      <p className="img-footer">
        Figure 3: Map of the Dixon farming systems. Click on one of the 23 CRISP farming systems and read a short description.
      </p>
      <h3>
        <svg width={30} height={50}>
            <circle cx={15} cy={15} r={12} fill={'#2A66FF'}/>
            <text x={15} y={15} fontSize={16} fill="white" alignmentBaseline='central' textAnchor="middle">3</text>
        </svg>
        Bibliography
      </h3>
      <p className="short">
      Dixon, J., Gibbon, D.P., Gulliver, A., Hall, M., 2001. Farming systems and poverty: improving farmers’ livelihoods in a changing world. FAO; World Bank, Rome: Washington, D.C.<br/><br/>
      IPCC, 2022. Summary for Policymakers. Clim. Change 2022 Impacts Adapt. Vulnerability Contrib. Work. Group II Sixth Assess. Rep. Intergov. Panel Clim. Change.<br/><br/>
      IPCC, 2021. Annex VII: Glossary [Matthews, J.B.R., V. Möller, R. van Diemen, J.S. Fuglestvedt, V. Masson-Delmotte, C. Méndez, S. Semenov, A. Reisinger (eds.)]. Clim. Change 2021 Phys. Sci. Basis Contrib. Work. Group Sixth Assess. Rep. Intergov. Panel Clim. Change. https://doi.org/10.1017/9781009157896.022<br/><br/>
      Zebisch, M., Schneiderbauer, S., Renner, K., Below, T., Brossmann, M., Ederer, W., Schwan, S., 2017. Risk Supplement to the Vulnerability Sourcebook. Guidance on how to apply the Vulnerability Sourcebook’s approach with the new IPCC AR5 concept of climate risk. GIZ, Bonn.<br/><br/>
      Zebisch, M., Terzi, S., Pittore, M., Renner, K., Schneiderbauer, S., 2022. Climate Impact Chains—A Conceptual Modelling Approach for Climate Risk Assessment in the Context of Adaptation Planning, in: Kondrup, C., Mercogliano, P., Bosello, F., Mysiak, J., Scoccimarro, E., Rizzo, A., Ebrey, R., Ruiter, M. de, Jeuken, A., Watkiss, P. (Eds.), Climate Adaptation Modelling, Springer Climate. Springer International Publishing, Cham, pp. 217–224. https://doi.org/10.1007/978-3-030-86211-4_25
      </p>
      <h3>Funding partners</h3>
      <p className="short">
        The <span>CRISP-Tool</span> was developed by the <a href="https://www.giz.de/de/html/index.html" target="_blank" rel="noreferrer">Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ)</a> on behalf of the <a href="https://www.bmz.de/en" target="_blank" rel="noreferrer">German Federal Ministry for Economic Cooperation and Development (BMZ)</a> in cooperation with the <a href="https://alliancebioversityciat.org/" target="_blank" rel="noreferrer">Alliance of Bioversity International and the International Centre for Tropical Agriculture (CIAT)</a>, <a href="https://www.eurac.edu/en" target="_blank" rel="noreferrer">Eurac Research</a> and the <a href="https://www.unibz.it/" target="_blank" rel="noreferrer">Free University of Bolzano/Bozen</a>.
      </p>
    </div>
  }
}
export default Background;