import React, { Component } from 'react';
import FacetBar from './FacetBar';
import { LABELS } from '../../Constants';

class FacetBars extends Component {
    constructor(props) {
        super(props)
        this.margins = {
            top: 0,
            right: 25,
            bottom: 3,
            left: 0
        };
    }



    render() {
        const data = this.props.data.sort(/*(a, b) => (b.value - a.value)*/);

        return (
            <div className='facet-column'>
                <h6>{LABELS[this.props.category]}</h6>
                <div className='facet-bars'>
                    {
                        data.map(
                            (d, index) => <FacetBar 
                                category={this.props.category} 
                                key={index} 
                                data={d} 
                                height={25} >
                            </FacetBar>
                        )
                    }
                </div>                
            </div>
        );
    }
}

export default FacetBars;